import React from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceBankTransfer from "../ServiceBankTransfer";
import ServiceCashTransfer from "../ServiceCashTransfer";
import ServiceFaq from "../ServiceFaq";
import ServiceFeatures from "../ServiceFeatures";
import ServiceProcess from "../ServiceProcess";
import Assets from "../Layouts/Assets";
import { useService } from "../../logic/useService";
import { useDimensions } from "../../logic/Dimensions";

const CorporateMoneyTransfer = () => {
  let loc=window.location.pathname.split("/")[2]
  const result = useService(loc);
  const { width } = useDimensions();
  return (
    <>
      <ServiceBanner />
      {width >= 768 && <ServiceBannerFold />}
      <ServiceBankTransfer />
      <ServiceCashTransfer />
      <ServiceProcess />
      <ServiceFeatures />
      <ServiceFaq />
    </>
  );
};

export default CorporateMoneyTransfer;
