import React from "react";
import EnquireAddress from "../EnquireAddress";
import EnquireSection from "../EnquireSection";
import EnquireOtherBranches from "../EnquireOtherBranches";
import { useEnquiry } from "../../logic/useEnquiry";
import { useDimensions } from "../../logic/Dimensions";
import ServiceFaq from "../ServiceFaq";
const Enquire = () => {
  const { width } = useDimensions();
  const { enquiryData } = useEnquiry();

  return (
    <>
      <EnquireSection enquiryData={enquiryData} />
      <EnquireAddress enquiryData={enquiryData} />
      {width >= 768 && (<EnquireOtherBranches />)}
      <ServiceFaq />
    </>
  );
};

export default Enquire;
