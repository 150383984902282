import React, { useEffect, useState } from "react";
import AllBranches from "../AllBranches";
import Style from "./BranchLocator.module.scss";
import { Breadcrumb, DropdownButton, Dropdown } from "react-bootstrap";
import IcoMoon from "../Layouts/IcoMooon";
import * as _ from "lodash";
import { useDispatch } from "react-redux";
import { getBranches } from "../../store/slices/branchesSlice";
import { useBranchLocator } from "../../logic/useBranchLocator";
import { useNearestBranch } from "../../logic/useNearestBranch";

const BranchLocator = () => {
  const [currentLocationLoadMore, setCurrentLocationLoadMore] = useState(false);
  const [locTitle, setLocTitle] = useState("All Locations");
  const [currentLocation, setCurrentLocation] = useState("");

  const dispatch = useDispatch();

  const {
    branchData,
    searchHandleChange,
    searchTerm,
    searchData,
    setSearchData,
  } = useBranchLocator();

  useEffect(() => {
    if (branchData?.details) {
      let filteredItems = branchData?.details.filter((item) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setSearchData(filteredItems);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (branchData?.details) {
      setSearchData(branchData.details);
    }
  }, [branchData]);

  const { postLocation } = useNearestBranch();

  const locationChange = async (e) => {
    if (e !== "All") {
      const loc_data = _.find(branchData?.details, {
        term_id: parseInt(e),
      });

      setLocTitle(loc_data.name);
      await dispatch(getBranches({ cat: loc_data?.term_id }));
    } else {
      setLocTitle("All Location");
      await dispatch(getBranches());
    }
  };

  const getLocation = async (e) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position) {
        const location = `${position.coords.latitude},${position.coords.longitude}`;
        postLocation({ location: location, posts_per_page: 3, page: 1 });
        setCurrentLocationLoadMore(true);
        setCurrentLocation(location);
      }
    });
  };

  return (
    <>
      <div className={Style.branchlocpage}>
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="" className={Style.pathName}>
              Our Branches
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={Style.titlehead}>
            <div className={Style.titlesec}>
              <h2>Our Branches</h2>
            </div>
            <div className={Style.location}>
              <a className={Style.preciseloc} onClick={getLocation}>
                <span className={Style.icon}>
                  <IcoMoon icon="loc-pointer" size={20} />
                </span>
                Use Current Location
              </a>
              <DropdownButton
                id="department-dropdown"
                className={`dropdownbtn ${Style.dropdown}`}
                title={
                  <>
                    <IcoMoon icon="location" size={15} />
                    {locTitle}
                  </>
                }
                onSelect={(e) => {
                  locationChange(e);
                }}
              >
                <div className={Style.search_dropdown_result_search}>
                  <div className={Style.search_dropdown_result_search_wrap}>
                    <button>
                      <IcoMoon icon={"find"} size={14} />
                    </button>
                    <input
                      type="text"
                      placeholder="search"
                      value={searchTerm}
                      onChange={(e) => {
                        searchHandleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className={Style.search_dropdown_result_search_list}>
                  {searchTerm.length < 1 && (
                    <Dropdown.Item eventKey="All">All Locations</Dropdown.Item>
                  )}
                  {searchData &&
                    searchData.length > 0 &&
                    searchData.map((value, key) => {
                      return (
                        <Dropdown.Item eventKey={value.term_id} key={key}>
                          {value.name.toUpperCase()}
                        </Dropdown.Item>
                      );
                    })}
                </div>
              </DropdownButton>
            </div>
          </div>
          <div className={Style.loc_list}>
            <AllBranches
              currentLocationLoadMore={currentLocationLoadMore}
              currentLocation={currentLocation}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchLocator;
