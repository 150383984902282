import React, { useEffect, useState } from "react";
import moment, { months } from "moment";
import axios from "axios";

export const useCurrencyGraph = (selectDays) => {
  const [data, setData] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [selectedDate, setSelectedDate] = useState("1M");
  const [type, setType] = useState();
  const [graphData, setGraphData] = useState([]);
  const [active, setActive] = useState("1M");
  const [timeGrpah, setTimeGrpah] = useState()

  let endDate = moment(new Date().toDateString()).format("yyyy-MM-DD");

  // useEffect(() => {
  //   let startDate = moment(new Date().toDateString())
  //     .subtract(1, "month")
  //     .format("yyyy-MM-DD");
  //   getData(startDate, selectedCurrency);
  // }, [selectedCurrency]);

  const getData = (type) => {
    axios({
      method: "get",
      url: `http://sharafexchange.webc.in/wp-json/v1/get-exchangerates-data-json-file?duration=${type}`,

      responseType: "stream",
    })
      .then((res) => {

        let obj = res?.data?.data?.rates;

        let graphTime=res

        setTimeGrpah(res?.data?.data)

        setData(obj);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    getData(type,selectedCurrency);
  }, [type,selectedCurrency]);

  useEffect(() => {
    if (selectedDate == "5D") {
      setType("day");
    } else if (selectedDate == "10D") {
      setType("week");
    } else if (selectedDate == "15D") {
      setType("month");
    } else if (selectedDate == "1M") {
      setType("year");
    } else if (selectedDate == "6M") {
      setType("year");
    } else {
      setType("year");
    }
  }, [selectedDate]);

  useEffect(() => {
    if (data && Object.keys(data)?.length != 0) {
      const keyArr = Object.keys(data);
      let GrphData = [];
      keyArr.map((a, i) => {
        if (
          Math.ceil((5 / 100) * keyArr.length) === i ||
          Math.ceil((25 / 100) * keyArr.length) === i ||
          Math.ceil((50 / 100) * keyArr.length) === i ||
          Math.ceil((100 / 100) * keyArr.length) === i
        ) {
          GrphData.push([
            moment(new Date(a).toDateString()).format("DD MMM"),
            data[a][selectedCurrency],
          ]);
        } else {
          GrphData.push([, data[a][selectedCurrency]]);
        }
      });
      setGraphData([["Month", "Rates"], ...GrphData]);
    }
  }, [data]);

  const graphDays = ["5D", "10D", "15D", "1M", "6M", "1Y"];

  const graphOptions = {
    curveType: "function",
    legend: { position: "none" },
    pointSize: 3,
    series: [{ color: "orange" }],
    chartArea:{left:40,top:20,bottom:40,width:"100%",height:"100%"},
    hAxis: {
      viewWindow: {
        min: 0,
        max: 20,
      },
      ticks: [0, 50, 100],
    },
  };

  const exchangeDay = (timePeriod) => {
    setSelectedDate(timePeriod);
    setActive(timePeriod);
  };

  return {
    graphData,
    graphOptions,
    graphDays,
    exchangeDay,
    setSelectedCurrency,
    active,
    timeGrpah
  };
};
