import React from "react";
import Style from "../CertificationsTab.module.scss";
import Assets from "../../Layouts/Assets";

const Certificates = ({ filteredIndex }) => {
  return (
    <div className={Style.awards}>
      {filteredIndex?.["certifications-and-awards-image-url-items"] &&
        filteredIndex?.["certifications-and-awards-image-url-items"].length >
          0 &&
        filteredIndex?.["certifications-and-awards-image-url-items"].map(
          (value, key) => {
            return (
              <div className={Style.award_figwrap}>
                <figure className={Style.award_item_img}>
                  <img
                    src={value?.["certifications-and-awards-image-url"]?.url}
                    alt=""
                  />
                </figure>
              </div>
            );
          }
        )}
    </div>
  );
};

export default Certificates;
