import React from "react";
import Style from './RatesMobileView.module.scss';
import { Link } from "react-router-dom";
import IcoMoon from "../../Layouts/IcoMooon";
import { Button } from "react-bootstrap";

const RatesMobileView = () => {
    return (
        <div className={`d-md-none ${Style.tablesec}`}>
            <div className={`d-md-none ${Style.table}`}>
                <div className={Style.tablehead}>
                    <div className="container">
                        <div className={Style.currency}>Currency</div>
                        <div className={Style.dd_dt}>DD/TT</div>
                        <div className={Style.fc_buy}>FC Buy</div>
                        <div className={Style.fc_cell}>FC Sell</div>
                    </div>
                </div> 
                <div className={Style.bodynote}>
                    <div className="container">
                        <ul className={Style.tablebody}>
                            <li>
                                <div className={Style.currency}>Indian Ruppee</div>
                                <div className={Style.dd_dt}>20.00</div>
                                <div className={Style.fc_buy}>0.04185</div>
                                <div className={Style.fc_cell}>0.04345</div>
                            </li>
                            <li>
                                <div className={Style.currency}>Indian Ruppee</div>
                                <div className={Style.dd_dt}>20.00</div>
                                <div className={Style.fc_buy}>0.04185</div>
                                <div className={Style.fc_cell}>0.04345</div>
                            </li>
                            <li>
                                <div className={Style.currency}>Indian Ruppee</div>
                                <div className={Style.dd_dt}>20.00</div>
                                <div className={Style.fc_buy}>0.04185</div>
                                <div className={Style.fc_cell}>0.04345</div>
                            </li>
                            <li>
                                <div className={Style.currency}>Indian Ruppee</div>
                                <div className={Style.dd_dt}>20.00</div>
                                <div className={Style.fc_buy}>0.04185</div>
                                <div className={Style.fc_cell}>0.04345</div>
                            </li>
                            <li>
                                <div className={Style.currency}>Indian Ruppee</div>
                                <div className={Style.dd_dt}>20.00</div>
                                <div className={Style.fc_buy}>0.04185</div>
                                <div className={Style.fc_cell}>0.04345</div>
                            </li>
                            <li>
                                <div className={Style.currency}>Indian Ruppee</div>
                                <div className={Style.dd_dt}>20.00</div>
                                <div className={Style.fc_buy}>0.04185</div>
                                <div className={Style.fc_cell}>0.04345</div>
                            </li>
                        </ul>   
                        <div className={Style.tablenote}>
                            <p>Rates are indicative and subject to change. Contact our <Link to={'/branch-locator'}>Nearest Branch</Link> for the latest rates.</p>    
                        </div>
                        <div className={Style.btnwrap}>
                            <Button className={`btn btn-brdr ${Style.loadmore}`}><span>Load More</span></Button>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RatesMobileView;