import React from "react";
import { Link } from "react-router-dom";
import Style from "../../Footer.module.scss";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const ServicesMenu = () => {
  let location = useLocation();

  const { footerData } = useSelector((state) => state.footer);

  let footerData1 = footerData?.details?.menus?.footer_menu_services
    .slice(0, 4)
    .map((item, index) => {
      let data = { ...item, url: `/services${item.url}` };
      return data;
    });

  let footerData2 = footerData?.details?.menus?.footer_menu_usrati.map(
    (item, index) => {
      let data = { ...item, url: `/services${item.url}` };
      return data;
    }
  );

  return (
    <div className="mobile-hide">
      {footerData && <div className={Style.titlesec}>Services</div>}
      <div className={Style.menuwrap}>
        <ul>
          {footerData?.details?.menus.footer_menu_services &&
            footerData?.details?.menus?.footer_menu_services.length > 0 &&
            footerData1.slice(0, 4).map((value, key) => {
              let activeService1 = location.pathname == value.url;
              return (
                <li key={key} className={activeService1 ? Style.active : ""}>
                  <Link key={value.id} to={value.url ? value.url : "/"}>
                    {value.title}
                  </Link>
                </li>
              );
            })}
        </ul>
        <ul>
          {footerData?.details?.menus.footer_menu_usrati &&
            footerData?.details?.menus?.footer_menu_usrati.length > 0 &&
            footerData2.map((value, key) => {
              let activeService2 = location.pathname == value.url;
              return (
                <li key={key} className={activeService2 ? Style.active : ""}>
                  <Link key={value.id} to={value.url ? value.url : "/"}>
                    {value.title}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default ServicesMenu;
