import React, { useEffect } from "react";
import Style from "./UsrariBenefit.module.scss";
import Assets from "../Layouts/Assets";
import { useSelector } from "react-redux";
import ServiceContent from "../ServiceContentSection/ServiceContent";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceContentTwo from "../ServiceContentSection/ServiceContentTwo";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const UsratiBenefit = () => {
  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  useEffect(() => {
    gsap.fromTo(
      `.${Style.usratibenefitsec} img`,
      {
        scale: 1.2,
        scrollTrigger: {
          trigger: `.${Style.usratibenefitsec} img`,
          scrub: 1.2,
        },
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: `.${Style.usratibenefitsec} img`,
          scrub: 1.2,
        },
      },
      0.2
    );
  });

  let data =
    serviceData?.details[0] &&
    serviceData?.details[0]?.page_sections?.["page-details-section-one"]?.[
      "page-details-section-one-items"
    ]?.[1];

  return (
    <div
      className={
        loaderClass
          ? `${Style.usratibenefitsec} ${Style.loaded}`
          : Style.usratibenefitsec
      }
    >
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.imgcol}>
            <div className={Style.figwrapper}>
              <figure className={Style.imgwrap}>
                <img
                  src={data?.["page-details-section-one-image"]?.url}
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className={Style.textcol}>
            <ServiceContentTwo
              loaderClass={loaderClass}
              serviceData={serviceData}
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsratiBenefit;
