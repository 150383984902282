import React, { useState, useEffect } from "react";
import Style from "../CertificationsTab.module.scss";
import ReactHtmlParser from "react-html-parser";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const CertificationsTabList = ({
  setFilteredIndex,
  filteredIndex,
  aboutCertificationsData,
}) => {
  const { isActive } = useSelector((state) => state.pageAbout);


  const [loadedClass, setLoadedClass] = useState(false);
  const filteredHandler = (value) => {
    setFilteredIndex(value);
    setLoadedClass(true);
    setTimeout(() => setLoadedClass(false), 2000);
  };


  return (
    <div className={`${Style.tabwrapper} ${loadedClass ? Style.loaded : ""}`}>
      <ul>
        {aboutCertificationsData?.details?.[
          "section-certifications-and-awards"
        ]?.["section-certifications-and-awards-items"] &&
          aboutCertificationsData?.details?.[
            "section-certifications-and-awards"
          ]?.["section-certifications-and-awards-items"].length > 0 &&
          aboutCertificationsData?.details?.[
            "section-certifications-and-awards"
          ]?.["section-certifications-and-awards-items"].map((value, key) => {
            return (
              <li
                className={`award-el ${value === filteredIndex ? Style.active : ""
                  }`}
                onClick={() => filteredHandler(value)}
                key={key}
              >
                {ReactHtmlParser(value["certifications-and-awards-tab-title"])}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
export default CertificationsTabList;
