import React, { useState, useEffect } from "react";
import { useAboutUs } from "../../logic/useAboutUs";
import Style from "./CertificationsTab.module.scss";
import ReactHtmlParser from "react-html-parser";
import * as _ from "lodash";
import CertificationsTabList from "./CertificationsTabList";
import CertificationsTabResults from "./CertificationsTabResults";
import CertificationAccordion from "./MobileView/CertificationAccordion";
import { useSelector } from "react-redux";

const CertificationsTab = () => {
  const [filteredIndex, setFilteredIndex] = useState(null);

  const { aboutCertificationsData } = useAboutUs();

  const { isActive } = useSelector((state) => state.pageAbout);

  useEffect(() => {
    if (
      aboutCertificationsData?.details?.["section-certifications-and-awards"]?.[
        "section-certifications-and-awards-items"
      ].length > 0
    ) {
      const firstData = _.first(
        aboutCertificationsData?.details?.[
          "section-certifications-and-awards"
        ]?.["section-certifications-and-awards-items"]
      );
      setFilteredIndex(firstData);
    }
  }, [aboutCertificationsData]);

  return (
    <div
      className={
        isActive
          ? `${Style.certification_tabsec} ${Style.loaded}`
          : Style.certification_tabsec
      }
    >
      <div className="container">
        <div className={Style.sectitle}>
          <h2>
            {ReactHtmlParser(
              aboutCertificationsData?.details?.[
                "section-certifications-and-awards"
              ]?.["section-certifications-and-awards-title"]
            )}
          </h2>
        </div>
        <div className={Style.wrapper}>
          <div className={Style.tabcol}>
            <CertificationsTabList
              aboutCertificationsData={aboutCertificationsData}
              setFilteredIndex={setFilteredIndex}
              filteredIndex={filteredIndex}
            />
          </div>
          <div className={Style.resultcol}>
            <CertificationsTabResults filteredIndex={filteredIndex} />
          </div>
        </div>

        {/* Only Shows After Screen Width 767.98px */}
        <div className={Style.accordianwrap}>
          <CertificationAccordion
            aboutCertificationsData={aboutCertificationsData}
          />
        </div>
      </div>
    </div>
  );
};

export default CertificationsTab;
