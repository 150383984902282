import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import aboutUsSlice from "./slices/aboutUsSlice";
import blogSlice from "./slices/blogSlice";
import branchLocatorSlice from "./slices/branchLocatorSlice";
import careersSlice from "./slices/careersSlice";
import enquirySlice from "./slices/enquirySlice";
import faqSlice from "./slices/faqSlice";
import footerSlice from "./slices/footerSlice";
import homeAboutUsSlice from "./slices/homeAboutUsSlice";
import homeBannerSlice from "./slices/homeBannerSlice";
import homeMobAppSlice from "./slices/homeMobAppSlice";
import homeOurServiceSlice from "./slices/homeOurServiceSlice";
import homeTestimonialSlice from "./slices/homeTestimonialSlice";
import menuSlice from "./slices/menuSlice";
import serviceSlice from "./slices/serviceSlice";
import singleBlogSlice from "./slices/singleBlogSlice";
import termsAndConditionsSlice from "./slices/termsAndConditionsSlice";
import formSlice from "./slices/formSlice";
import careersJobSlice from "./slices/careersJobSlice";
import careersJobSingleSlice from "./slices/careersJobSingleSlice";
import homeCurrentOpeningSlice from "./slices/homeCurrentOpeningSlice";
import branchesSlice from "./slices/branchesSlice";
import nearestBranchSlice from "./slices/nearestBranchSlice";
import blogCategorySlice from "./slices/blogCategorySlice";
import homeBranchLocatorSlice from "./slices/homeBranchLocatorSlice";
import currencyRatesSlice from "./slices/currencyRatesSlice";
import dynamicMenuSlice from "./slices/dynamicMenuSlice";
import searchSlice from "./slices/searchSlice";

const menuSlicePersisted = persistReducer({key:'menu',storage},menuSlice)


const rootReducer = combineReducers({
  menu: menuSlicePersisted,
  footer: footerSlice,
  mobile: homeMobAppSlice,
  branch: branchLocatorSlice,
  about: homeAboutUsSlice,
  service: homeOurServiceSlice,
  testimonials: homeTestimonialSlice,
  blog: blogSlice,
  singleBlog: singleBlogSlice,
  banner: homeBannerSlice,
  pageAbout: aboutUsSlice,
  enquiry: enquirySlice,
  pageService: serviceSlice,
  faq: faqSlice,
  terms: termsAndConditionsSlice,
  careers: careersSlice,
  form: formSlice,
  careersJob: careersJobSlice,
  careersJobSingle: careersJobSingleSlice,
  current: homeCurrentOpeningSlice,
  branches: branchesSlice,
  nearestBranch: nearestBranchSlice,
  blogCat: blogCategorySlice,
  homebranch:homeBranchLocatorSlice,
  currencyRates:currencyRatesSlice,
  currencyTableRates:currencyRatesSlice,
  dynamic:dynamicMenuSlice,
  search:searchSlice
  
});

export default rootReducer;
