import { useState } from "react";
import Style from "../Faq.module.scss";
import { Accordion } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const FaqResult = ({ filteredIndex }) => {
  const [activeAccordtionIndex, setActiveAccordtionIndex] = useState(-1);

  const handleSelectAccordion = (key) => {
    if (activeAccordtionIndex === key) {
      setActiveAccordtionIndex(-1);
    } else {
      setActiveAccordtionIndex(key);
    }
  };

  return (
    <>
      {filteredIndex != null && (
        <Accordion
          defaultActiveKey={[1]}
          alwaysOpen
          className={`accordionview secondary ${Style.accordions}`}
        >
          {filteredIndex?.faqs &&
            filteredIndex?.faqs.length > 0 &&
            filteredIndex?.faqs.map((value, key) => {
              return (
                <Accordion.Item
                  eventKey={filteredIndex.id}
                  className={
                    activeAccordtionIndex == key
                      ? `active ${Style.acc_item}`
                      : `${Style.acc_item}`
                  }
                  key={key}
                  onClick={() => handleSelectAccordion(key)}
                >
                  <Accordion.Header className={Style.acc_head}>
                    {value.question}
                  </Accordion.Header>
                  <Accordion.Body className={Style.acc_content}>
                    <p>{ReactHtmlParser(value.answer)}</p>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      )}

      {/* <div className={Style.btnwrap}>
        <button className={`btn btn-brdr ${Style.loadmore}`}><span>Load More</span></button>
      </div> */}
    </>
  );
};

export default FaqResult;
