import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Style from "./ServiceBanner.module.scss";
import ReactHtmlParser from "react-html-parser";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const ServiceBanner = () => {
  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );
  const servicebnrshape = useRef();
  useEffect(() => {
    gsap.to(servicebnrshape.current, {
      x: 150,
      y: 1000,
      duration: 0.5,
      delay: 0.4,
      width: 50,
      height: 50,
      scrollTrigger: {
        trigger: ".home_servicesec",
        scrub: 2,
      },
    });
  }, []);
  return (
    <div
      className={
        loaderClass ? `${Style.servicebnr} ${Style.loaded}` : Style.servicebnr
      }
    >
      <span className={Style.circleshape} ref={servicebnrshape}></span>
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.textcol}>
            <div className={Style.textwrap}>
              <div className={Style.textwrap}>
                <header className={Style.smtitle}>
                  {serviceData?.details[0] &&
                    serviceData?.details[0]?.page_sections?.[
                      "banner-field-set"
                    ]?.["banner-field-set-first-small-text"]}
                </header>
                <h1>
                  {serviceData?.details[0] &&
                    serviceData?.details[0]?.page_sections?.[
                      "banner-field-set"
                    ]?.["banner-field-set-main-text"]}
                </h1>
                <p>
                  {ReactHtmlParser(
                    serviceData?.details[0] &&
                      serviceData?.details[0]?.page_sections?.[
                        "banner-field-set"
                      ]?.["banner-field-set-main-description"]
                  )}
                </p>
                {/* {serviceData && (
                  <Link to="#" className={`btn btn-brdr ${Style.bnrbtn}`}>
                    <span>
                      {serviceData?.details[0] &&
                        serviceData?.details[0]?.page_sections?.[
                          "banner-field-set"
                        ]?.["banner-field-set-button-text"]}
                    </span>
                  </Link>
                )} */}
              </div>
            </div>
          </div>

          <div className={Style.imgcol}>
            <div className={Style.figwrap}>
              <figure className={Style.imgsec}>
                <img
                  src={
                    serviceData?.details[0] &&
                    serviceData?.details[0]?.page_sections?.[
                      "banner-field-set"
                    ]?.["banner-field-set-image"]?.url
                  }
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBanner;
