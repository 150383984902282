import React, { useEffect, useRef, useState } from "react";
import Style from "./EnquireSection.module.scss";
import EnquireDetails from "./EnquireDetails";
import EnquireForm from "./EnquireForm";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useDimensions } from "../../logic/Dimensions";

gsap.registerPlugin(ScrollTrigger);

const EnquireSection = ({ enquiryData }) => {
  const { width } = useDimensions();

  const { activeClass } = useSelector((state) => state.enquiry);

  const circleshape = useRef(),
    circletrigger = useRef();
  useEffect(() => {
    if (enquiryData) {
    }
    gsap.to(circleshape.current, {
      x: -200,
      y: 700,
      rotate: 20,
      duration: 1,
      delay: 0.4,
      opacity: 0.6,
      width: 200,
      height: 200,
      scrollTrigger: {
        trigger: circletrigger.current,
        scrub: 1.5,
      },
    });
  });
  return (
    <div
      className={
        activeClass ? `${Style.enquiresec} ${Style.loaded}` : Style.enquiresec
      }
    >
      <span className={Style.circleshape} ref={circleshape}></span>
      <div className="container">
        {width < 768 && (
          <div className={Style.titlesec}>
            <h1>
              {
                enquiryData?.details?.["page-more-details"]?.[
                  "enquiry-page-details"
                ]?.["banner-title"]
              }
            </h1>
          </div>
        )}
        <div className={Style.wrapper}>
          <div>
            <div className={Style.cntsec}>
              {width >= 768 && (
                <>
                  <div className={Style.titlesec}>
                    <h1>
                      {
                        enquiryData?.details?.["page-more-details"]?.[
                          "enquiry-page-details"
                        ]?.["banner-title"]
                      }
                    </h1>
                  </div>
                  <div className={Style.cnt}>
                    <p>
                      {ReactHtmlParser(
                        enquiryData?.details?.["page-more-details"]?.[
                          "enquiry-page-details"
                        ]?.["banner-description"]
                      )}
                    </p>
                  </div>
                </>
              )}

              <div className={Style.contactdetails}>
                {enquiryData && <EnquireDetails enquiryData={enquiryData} />}
              </div>
            </div>
          </div>
          <div>
            <div className={Style.formouterwrap}>
              <EnquireForm />
            </div>
          </div>
        </div>
      </div>
      <span ref={circletrigger}></span>
    </div>
  );
};
export default EnquireSection;
