import React from 'react'

import Assets from '../Layouts/Assets';

import Style from './Loader.module.scss';

const Loader = () => {
  return (
    <figure className={Style.loader_gif}>
        <img src={Assets.page_loader} alt="Loader" />
    </figure>
  )
}

export default Loader