import React from "react";
import Style from "../Footer.module.scss";
import SocialMedias from "../../../SocialMedias";
import CompanyMenu from "./CompanyMenu";
import ServicesMenu from "./ServicesMenu";
import CompanyDropMenu from "./CompanyMenu/CompanyDropMenu";
import ServicesDropMenu from "./ServicesMenu/ServicesDropMenu";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const FooterMenus = () => {
  const { footerData } = useSelector((state) => state.footer);

  return (
    <div className={Style.ftr_menu_addr}>
      <div className={`${Style.cols} ${Style.companycol}`}>
        <CompanyMenu />
        <CompanyDropMenu />
      </div>
      <div className={`${Style.cols} ${Style.servicecol}`}>
        <ServicesMenu />
        <ServicesDropMenu />
      </div>
      <div className={`${Style.cols} ${Style.officecol}`}>
        <div className={Style.reachout}>
          {footerData && <div className={Style.titlesec}>Head Office</div>}
          <p>
            {ReactHtmlParser(
              footerData?.details?.footer_more_details?.wac_footer_address
            )}
          </p>
          <SocialMedias footerData={footerData} />
        </div>
      </div>
    </div>
  );
};
export default FooterMenus;
