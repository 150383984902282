import { useEffect } from "react";
import { getCareersJobSingle } from "../store/slices/careersJobSingleSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useCareersJobsSingle = () => {
  const { ID } = useParams();

  const dispatch = useDispatch();
  const careersJobSingleData = useSelector((state) => state.careersJobSingle);

  useEffect(() => {
    singleJobData();
  }, [ID]);

  const singleJobData = async () => {
    await dispatch(getCareersJobSingle({ j_id: ID }));
  };

  return {
    careersJobSingleData: careersJobSingleData?.careersJobSingleData,
  };
};
