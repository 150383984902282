import React, { useEffect, useRef } from "react";
import Style from "./MoneyExchange.module.scss";
import ExchangeImages from "./ExchangeImages";
import ExchangeContent from "./ExchangeContent";
import { useInView } from "react-intersection-observer";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MoneyExchange = () => {
  const triggercircle = useRef(),
    ovalcircle = useRef();
  useEffect(() => {
    gsap.to(ovalcircle.current, {
      y: 500,
      duration: 2,
      delay: 1,
      scrollTrigger: {
        trigger: triggercircle.current,
        scrub: 2,
      },
    });
  });

  const [ref1, inView1] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className={`${Style.moneyexchangesec} ${Style.loaded}`}>
      <span className={Style.circleshape} ref={ovalcircle}></span>
      <div className="container">
        <div ref={ref1} className={`row ${Style.wrapper} } `}>
          <div className={Style.cols}>
            <ExchangeImages />
          </div>
          <div ref={ref2} className={`${Style.cols} anim`}>
            <ExchangeContent />
          </div>
        </div>
      </div>
      <span ref={triggercircle}></span>
    </div>
  );
};

export default MoneyExchange;
