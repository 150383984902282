import { useState } from "react";
import { getSearch } from "../store/slices/searchSlice";
import { useDispatch, useSelector } from "react-redux";

export const useSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);

  const [searchData, setSearchData] = useState("");

  const openSearch = (e) => {
    setActiveSearch(true);
    document.querySelector("#common_search").focus();
  };
  const closeSearch = () => {
    setActiveSearch(false);
  };
  const dispatch = useDispatch();

  const searchHandleChange = (e) => {
    setSearchTerm(e.target.value);
    dispatch(getSearch({ serachSlug: e.target.value }))
      .unwrap()
      .then((value) => {
        setSearchData(value);
      });
  };

  return {
    searchData,
    searchTerm,
    searchHandleChange,
    activeSearch,
    setActiveSearch,
    openSearch,
    closeSearch,
    setSearchTerm,
  };
};
