import React, { useState } from "react";
import Assets from "../Layouts/Assets";
import Style from "./MediaDetail.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import RelatedTopics from "../RelatedTopics";
import { Breadcrumb } from "react-bootstrap";
import { useSingleBlog } from "../../logic/useSingleBlog";
import ReactHtmlParser from "react-html-parser";

import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
} from "react-share";
import { useSelector } from "react-redux";

const MediaDetail = () => {
  const { singleBlogData } = useSingleBlog();
  const [isCopied, setIsCopied] = useState(false);
  const { isActive } = useSelector((state) => state.singleBlog);

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className={isActive ? `container ${Style.loaded}` : "container"}>
        <div className={`${Style.smcontainer}`}>
          {singleBlogData ? (
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/media">Media</Breadcrumb.Item>
              <Breadcrumb.Item>
                {singleBlogData?.details?.single_post[0]?.categories}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {singleBlogData?.details?.single_post[0]?.post_title}
              </Breadcrumb.Item>
            </Breadcrumb>
          ) : (
            <div className={`${Style.postshimmer} ${Style.breadcrumb}`}>
              <span>Home</span>
              <span>Media</span>
              <span>Events</span>
              <span>Sign Up For Usrati And G...</span>
            </div>
          )}
        </div>
      </div>

      {singleBlogData ? (
        <div className={Style.postdetail}>
          <div className="container">
            <div className={`${Style.smcontainer}`}>
              <div className={Style.smwrapper}>
                <div className={Style.title_detail}>
                  <div className={`smtitle ${Style.smtitle}`}>
                    {singleBlogData?.details?.single_post[0]?.categories}
                  </div>
                  <h2>{singleBlogData?.details?.single_post[0]?.post_title}</h2>
                  <div className={Style.postdate}>
                    <p>
                      {
                        singleBlogData?.details?.single_post[0]
                          ?.post_date_beautiful
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className={Style.postfigwrap}>
                {singleBlogData?.details?.single_post[0]?.post_thumbnail_url ? (
                  <figure className={Style.imgwrap}>
                    <img
                      src={
                        singleBlogData?.details?.single_post[0]
                          ?.post_thumbnail_url
                      }
                      alt=""
                    />
                  </figure>
                ) : (
                  ""
                )}
              </div>
              <div className={Style.smwrapper}>
                <div className={Style.postcnt}>
                  {ReactHtmlParser(
                    singleBlogData?.details?.single_post[0]?.post_content
                  )}
                </div>
                <div className={Style.postdetail_share}>
                  <div className={Style.postdetail}>
                    <ul>
                      <li>
                        Posted by{" "}
                        {
                          singleBlogData?.details?.single_post[0]
                            ?.post_author_name
                        }
                      </li>
                      <li>
                        Category:{" "}
                        {singleBlogData?.details?.single_post[0]?.categories}
                      </li>
                    </ul>
                  </div>
                  <div className={Style.share}>
                    <span>Share</span>
                    <ul>
                      <li>
                        <Link to="#" target="_blank">
                          <FacebookShareButton
                            url={`${window.location.host}/media-detail/${singleBlogData?.details?.single_post[0]?.post_thumbnail_url}`}
                          >
                            <IcoMoon icon="facebook" size={17.97} />
                          </FacebookShareButton>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <TwitterShareButton
                            url={`${window.location.host}/media-detail/${singleBlogData?.details?.single_post[0]?.post_thumbnail_url}`}
                          >
                            <IcoMoon icon="twitter" size={19.65} />
                          </TwitterShareButton>
                        </Link>
                      </li>
                      <li>
                        <a href={`https://www.instagram.com/`} target="_blank">
                          <IcoMoon icon="insta" size={17.97} />
                        </a>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            handleCopyClick(
                              `${window.location.host}/media-detail/${singleBlogData?.details?.single_post[0]?.categories_ids}/${singleBlogData?.details?.single_post[0]?.ID}`
                            );
                          }}
                        >
                          <IcoMoon icon="attach" size={15.77} />
                        </Link>
                        {isCopied && (
                          <span className={Style.clip_to_board}>copied</span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className={Style.smcontainer}>
            <div className={Style.postshimmer}>
              <div className={Style.smwrapper}>
                <div className={Style.title_detail}>
                  <div
                    className={`${Style.postshimmer_line} ${Style.postshimmer_smtitle}`}
                  ></div>
                  <div
                    className={`${Style.postshimmer_line} ${Style.postshimmer_title}`}
                  ></div>
                  <div
                    className={`${Style.postshimmer_line} ${Style.postshimmer_date}`}
                  ></div>
                </div>
              </div>
              <div
                className={`${Style.postshimmer_line} ${Style.postshimmer_img}`}
              ></div>
              <div className={Style.smwrapper}>
                <div className={Style.postshimmer_cnt}>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                </div>
                <div className={Style.postshimmer_cnt}>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <RelatedTopics singleBlogData={singleBlogData} />
    </>
  );
};

export default MediaDetail;
