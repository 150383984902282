import axios from "axios";

const BASE_URL = process.env.REACT_APP_APIURL;





export const getAxiosInstance = async () => {
  let lang =
    localStorage.getItem("lang") == undefined
      ? "en"
      : localStorage.getItem("lang");
  const instance = axios.create({
    baseURL: BASE_URL,
    params: { lang },
    headers: {
      Accept: "application/json",
    },
  });
  instance.interceptors.request.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};
