import { useEffect } from "react";
import { getCurrencyRates } from "../store/slices/currencyRatesSlice";
import { useDispatch, useSelector } from "react-redux";

export const useCurrencyRates = () => {
  const dispatch = useDispatch();
  const currencyRatesData = useSelector((state) => state.currencyRates);

  useEffect(() => {
    ratesOfCurrency();
  }, []);

  const ratesOfCurrency = async () => {
    await dispatch(getCurrencyRates());
  };

  return {
    currencyRatesData: currencyRatesData?.currencyRatesData,
    ratesOfCurrency,
  };
};
