import React, { useState, useEffect } from "react";
import Style from "./HomeServicesTab.module.scss";
import { Link, useLocation } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import ServiceTabs from "./ServiceTabs";
import ServiceTabResults from "./ServiceTabResults";
import ServiceAccordion from "./MobileView/ServiceAccordion";
import * as _ from "lodash";
import { useDimensions } from "../../logic/Dimensions";
import { useSelector } from "react-redux";

const HomeServicesTab = ({ result, serviceData, title, loaderClass }) => {
  const { width } = useDimensions();
  const [valueImage, setValueImage] = useState(false);
  const [filteredIndex, setFilteredIndex] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (serviceData?.length > 0) {
      const firstData = _.first(serviceData);
      setFilteredIndex(firstData);
    }
    const pathName = window.location.pathname;

    if (width <= 768 && pathName === "/services/value-added-services") {
      setValueImage(true);
    } else {
      setValueImage(false);
    }
  }, [serviceData, width]);

  return (
    <div
      className={
        loaderClass
          ? `home_servicesec ${Style.home_servicesec} ${Style.loaded} ${
              location.pathname !== "/" && `${Style.servicepage_tab}`
            }`
          : `home_servicesec ${Style.home_servicesec}  ${
              location.pathname !== "/" && `${Style.servicepage_tab}`
            }`
      }
    >
      <div className="container">
        <div className={Style.servicetitle}>
          <h2>{title}</h2>
        </div>
        <div className={Style.wrapper}>
          <div className={Style.tabcol}>
            <ServiceTabs
              serviceData={serviceData}
              filteredIndex={filteredIndex}
              setFilteredIndex={setFilteredIndex}
            />
          </div>
          <div className={Style.resultcol}>
            <ServiceTabResults
              filteredIndex={filteredIndex}
              serviceData={serviceData}
              loaderClass={loaderClass}
            />
          </div>
        </div>

        {width < 992 && (
          <div className={Style.accordianwrap}>
            <ServiceAccordion
              serviceData={serviceData}
              valueImage={valueImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeServicesTab;
