import React, { useEffect, useState } from "react";
import Style from "./MobileMenu.module.scss";
import { Link } from "react-router-dom";
import Icomoon from "../../IcoMooon";
import LoginSignup from "../LoginSignup";
import { Accordion } from "react-bootstrap";
import { useHeader } from "../../../../logic/useHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MobileMenu = () => {
  let location = useLocation();

  const [dark, setDark] = useState(localStorage.getItem("lang"));

  const { menuData } = useHeader();
  const { isActive } = useSelector((state) => state.menu);

  const mainMenu = () => {
    let bodytag = document.body;
    bodytag.classList.toggle("show-menu");
  };

  const menuSelect = () => {
    document.body.classList.remove("show-menu");
  };

  const clickLang = (lang) => {
    if (lang === "en") {
      setDark("en");
    } else {
      setDark("ar");
    }
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  return (
    <>
      <nav className={Style.mainav}>
        {isActive ? (
          <ul>
            {menuData?.details &&
              menuData?.details?.map((item, navIndex) => {
                let activeMenu = location.pathname == item.url;
                return (
                  <>
                    {item?.title == "Services" ? (
                      <li
                        className={
                          location.pathname.includes("services")
                            ? Style.active
                            : ""
                        }
                      >
                        <Accordion className={Style.menudropdown}>
                          <Accordion.Item
                            key={navIndex}
                            className={Style.menu_item}
                            eventKey={navIndex}
                          >
                            <Accordion.Header className={Style.parentmenu}>
                              {item?.title}
                            </Accordion.Header>
                            <Accordion.Body className={Style.menu_list}>
                              <ul>
                                {item.sub_menu.map((menuItem, menuIndex) => {
                                  let activeSubMenu =
                                    location.pathname == menuItem.path;
                                  return (
                                    <li
                                      className={
                                        activeSubMenu ? Style.active : ""
                                      }
                                      onClick={() => menuSelect()}
                                    >
                                      <Link to={`${menuItem.url}`}>
                                        {menuItem?.title}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </li>
                    ) : (
                      <li
                        className={activeMenu ? Style.active : ""}
                        onClick={menuSelect}
                      >
                        <Link to={`${item?.url}`}>{item?.title}</Link>
                      </li>
                    )}
                  </>
                );
              })}

            <li>
              <div className={Style.menudropdown}>
                <div className={Style.menu_item}>
                  <div
                    className={
                      dark === "en"
                        ? `${Style.activeLang} ${Style.parentmenu}`
                        : Style.parentmenu
                    }
                  >
                    <button
                      onClick={() => {
                        clickLang("en");
                      }}
                    >
                      <span className={Style.icons}>
                        <Icomoon icon="globe" size={22} />
                      </span>
                      <span>English</span>
                    </button>
                  </div>
                  <div
                    className={
                      dark === "ar"
                        ? `${Style.activeLang} ${Style.parentmenu}`
                        : Style.parentmenu
                    }
                  >
                    <button
                      onClick={() => {
                        clickLang("ar");
                      }}
                    >
                      <span className={Style.icons}>
                        <Icomoon icon="globe" size={22} />
                      </span>
                      <span>Arabic</span>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        ) : (
          <ul>
            {navMenu.map((item, navIndex) => {
              let activeMenu = location.pathname == item.path;

              return (
                <>
                  {item.name == "Services" ? (
                    <li
                      className={
                        location.pathname.includes("services")
                          ? Style.active
                          : ""
                      }
                    >
                      <Accordion className={Style.menudropdown}>
                        <Accordion.Item
                          key={navIndex}
                          className={Style.menu_item}
                          eventKey={navIndex}
                        >
                          <Accordion.Header className={Style.parentmenu}>
                            {item.name}
                          </Accordion.Header>
                          <Accordion.Body className={Style.menu_list}>
                            <ul>
                              {item.sub_menu.map((menuItem, menuIndex) => {
                                let activeSubMenu =
                                  location.pathname == menuItem.path;
                                return (
                                  <li
                                    className={
                                      activeSubMenu ? Style.active : ""
                                    }
                                    onClick={() => menuSelect()}
                                  >
                                    <Link to={`${menuItem.path}`}>
                                      {menuItem.name}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </li>
                  ) : (
                    <li
                      className={activeMenu ? Style.active : ""}
                      onClick={menuSelect}
                    >
                      <Link to={`${item.path}`}>{item.name}</Link>
                    </li>
                  )}
                </>
              );
            })}

            <li>
              <div className={Style.menudropdown}>
                <div className={Style.menu_item}>
                  <div
                    className={
                      dark === "en"
                        ? `${Style.activeLang} ${Style.parentmenu}`
                        : Style.parentmenu
                    }
                  >
                    <button
                      onClick={() => {
                        clickLang("en");
                      }}
                    >
                      <span className={Style.icons}>
                        <Icomoon icon="globe" size={22} />
                      </span>
                      <span>English</span>
                    </button>
                  </div>
                  <div
                    className={
                      dark === "ar"
                        ? `${Style.activeLang} ${Style.parentmenu}`
                        : Style.parentmenu
                    }
                  >
                    <button
                      onClick={() => {
                        clickLang("ar");
                      }}
                    >
                      <span className={Style.icons}>
                        <Icomoon icon="globe" size={22} />
                      </span>
                      <span>Arabic</span>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        )}

        <LoginSignup />
      </nav>
      <div className={Style.mob_btn} onClick={mainMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={Style.overlay}></div>
    </>
  );
};
export default MobileMenu;

let navMenu = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About Us",
    path: "/about-us",
  },
  {
    name: "Services",
    path: "",
    sub_menu: [
      {
        name: "Currency Exchange",
        path: "/services/currency-exchange",
      },
      {
        name: "Retail Money Transfer",
        path: "/services/retail-money-transfer",
      },
      {
        name: "Corporate Money Transfer",
        path: "/services/corporate-money-transfer",
      },
      {
        name: "Value Added Services",
        path: "/services/value-added-services",
      },
      {
        name: "Usrati",
        path: "/services/usrati-program",
      },
      {
        name: "Buy Back Guarantee",
        path: "/services/buy-back-guarantee",
      },
      {
        name: " BCD",
        path: "/services/bulk-cash-department",
      },
    ],
  },
  {
    name: "Media",
    path: "/media",
  },
  {
    name: "Careers",
    path: "/careers",
  },
  {
    name: "Enquire",
    path: "/enquiry",
  },
  {
    name: "Branches",
    path: "/branch-locator",
  },
];
