import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
import IcoMoon from "../../Layouts/IcoMooon";
import Style from "./CareerJobList.module.scss";
import { Link } from "react-router-dom";
import { getCareersJob } from "../../../store/slices/careersJobSlice";
import { useDispatch } from "react-redux";

const CareerJobList = ({ careersJobData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const onPagination = async (pages) => {
    if (pages != careersJobData?.details?.current_page) {
      const resultAction = await dispatch(
        getCareersJob({ posts_per_page: "2", page: pages })
      );

      if (getCareersJob.fulfilled.match(resultAction)) {
        const result = resultAction?.payload?.data?.details;
        setCurrentPage(result?.current_page);
      }
    }
  };

  return (
    <>
      <div
        className={
          careersJobData
            ? `${Style.resultwrap} ${Style.loaded}`
            : Style.resultwrap
        }
      >
        {careersJobData?.details?.jobs &&
          careersJobData?.details?.jobs.length > 0 &&
          careersJobData?.details?.jobs.map((value, key) => {
            return (
              <Link
                to={`/careers-detail/${value.slug}/${value.ID}`}
                className={Style.resultitem}
                key={key}
              >
                <div className={`${Style.role} `}>
                  <span>Role</span>
                  <div className={Style.valuename}>{value.role}</div>
                </div>
                <div className={Style.department}>
                  <span>Department</span>
                  <div className={Style.valuename}>{value.department}</div>
                </div>
                <div className={Style.location}>
                  <span>Location</span>
                  <div className={Style.valuename}>{value.location}</div>
                </div>
              </Link>
            );
          })}
      </div>
      {careersJobData && (
        <Pagination>
          <Pagination.Prev
            className={currentPage != 1 ? "active" : " disabled"}
          >
            <IcoMoon
              icon="swiper-prev"
              size={16}
              onClick={() => {
                onPagination(currentPage - 1);
              }}
            ></IcoMoon>
          </Pagination.Prev>

          {Array(careersJobData?.details?.total_pages)
            .fill(careersJobData?.details?.total_pages)
            .map((value, index) => {
              return (
                <Pagination.Item
                  active={currentPage === index + 1 ? true : false}
                  onClick={() => {
                    onPagination(index + 1);
                  }}
                >
                  {index + 1}
                </Pagination.Item>
              );
            })}

          <Pagination.Next
            className={
              currentPage != careersJobData?.details?.total_pages
                ? "active"
                : "disabled"
            }
          >
            <IcoMoon
              icon="swiper-next"
              size={16}
              onClick={() => {
                onPagination(currentPage + 1);
              }}
            ></IcoMoon>
          </Pagination.Next>
        </Pagination>
      )}
    </>
  );
};

export default CareerJobList;
