import React, { useState } from "react";
import Style from "./LoginSignup.module.scss";
import ModalForm from "../../../ModalForm";
/* import ModalThanks from "../../../ModalThanks"; */
import { Modal, Button } from "react-bootstrap";
const LoginSignup = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className={Style.logins}>
      {/* <Button className={`btn btn-brdr ${Style.loginbtn}`}>
        <span>Sign In</span>
      </Button> */}
      <Button
        onClick={() => setModalShow(true)}
        className={`btn btn-primary ${Style.loginbtn}`}
      >
        <span>Become a Customer</span>
      </Button>
      <Modal
        size="lg"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="applynow-modal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ModalForm setModalShow={setModalShow} modalShow={modalShow} />
          {/* <ModalThanks /> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginSignup;
