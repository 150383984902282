import React from "react";
import { useCareersJobsSingle } from "../../logic/useCareersJobsSingle";
import Style from "./CareersDetailWhatYoullBeDoing.module.scss";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

const CareersDetailWhatYoullBeDoing = () => {
  const { careersJobSingleData } = useCareersJobsSingle();
  const { isActive } = useSelector((state) => state.careersJobSingle);

  return (
    <div
      className={
        isActive ? `${Style.jobdetails} ${Style.loaded}` : Style.jobdetails
      }
    >
      <div className="container">
        <div className={`smcontainer ${Style.wrapper}`}>
          <h2 className="h5">
            {
              careersJobSingleData?.details[0]?.more_details?.[
                "job-more_details"
              ]?.["job-more_details-items"][0]?.["job-more_details-items-title"]
            }
          </h2>
          <p>
            {ReactHtmlParser(
              careersJobSingleData?.details[0]?.more_details?.[
                "job-more_details"
              ]?.["job-more_details-items"][0]?.[
                "job-more_details-items-description"
              ]
            )}
          </p>
          <ul className="list">
            {careersJobSingleData?.details[0]?.more_details?.[
              "job-more_details"
            ]?.["job-more_details-items"][0]?.["job-more_details-items-list"] &&
              careersJobSingleData?.details[0]?.more_details?.[
                "job-more_details"
              ]?.["job-more_details-items"][0]?.["job-more_details-items-list"]
                .length > 0 &&
              careersJobSingleData?.details[0]?.more_details?.[
                "job-more_details"
              ]?.["job-more_details-items"][0]?.[
                "job-more_details-items-list"
              ].map((value, key) => {
                return (
                  <li key={key}>
                    {value?.["job-more_details-items-list-title"]}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CareersDetailWhatYoullBeDoing;
