import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Style from "./Media.module.scss";
import { Link } from "react-router-dom";
import { useBlog } from "../../logic/useBlog";
import { getBlog } from "../../store/slices/blogSlice";
import { useDispatch, useSelector } from "react-redux";
import { useBlogCategory } from "../../logic/useBlogCategory";
import ReactHtmlParser from "react-html-parser";
import ModalGallery from "./ModalGallery";

const Media = () => {
  const [isActive, setIsActive] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [catLoadMore, setCatLoadMore] = useState("");
  const [galleryModal, setGalleryModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();

  const { blogData, blog, blogDataStatus } = useBlog({
    posts_per_page: 5,
    page: 1,
  });

  const { blogCatData } = useBlogCategory();

  const { isActiveClass } = useSelector((state) => state.blogCat);

  const dispatch = useDispatch();

  const eventList = (list) => {
    dispatch(getBlog({ cat: list, posts_per_page: 5, page: 1 }));
  };

  const allClick = async () => {
    await dispatch(getBlog({ posts_per_page: 5, page: 1 }));
  };

  useEffect(() => {
    if (blogCatData?.details?.[0]?.term_id) {
      setIsActive(blogCatData?.details?.[0]?.term_id);
      dispatch(
        getBlog({
          cat: blogCatData?.details?.[0]?.term_id,
          posts_per_page: 5,
          page: 1,
        })
      );
    }
  }, [blogCatData]);

  const loadMoreclick = () => {
    if (
      blogData?.details?.more_details?.current_page !=
      blogData?.details?.more_details?.total_pages
    ) {
      const nextPage = blogData?.details?.more_details?.current_page + 1;
      setPageNumber(nextPage);
    }
  };

  useEffect(() => {
    if (pageNumber != 1) {
      blog({
        posts_per_page: 5,
        page: pageNumber,
        cat: catLoadMore,
      });
    }
  }, [pageNumber]);

  return (
    <div
      className={
        isActiveClass
          ? `${Style.mediasection} ${Style.loaded}`
          : Style.mediasection
      }
    >
      <div className="container">
        <div className={Style.title_n_tab}>
          <div className={Style.titlesec}>
            {blogCatData && <h2>Recent Posts</h2>}
          </div>
          <div className={Style.tabswrap}>
            <ul>
              <li
                onClick={() => {
                  allClick();
                  setIsActive("ALL");
                  setCatLoadMore("");
                }}
              >
                {blogCatData && (
                  // <Button
                  //   className={
                  //     isActive === "ALL"
                  //       ? `btn btn-brdr ${Style.tabtn} ${Style.active}`
                  //       : `btn btn-brdr ${Style.tabtn}`
                  //   }
                  // >
                  //   <span>All</span>
                  // </Button>
                  <></>
                )}
              </li>
              {blogCatData?.details &&
                blogCatData?.details.length > 0 &&
                blogCatData?.details.map((value, i) => {
                  return (
                    <>
                      <li
                        key={i}
                        onClick={() => {
                          eventList(value?.term_id);
                          setIsActive(value?.term_id);
                          setCatLoadMore(value?.term_id);
                        }}
                      >
                        <Button
                          className={
                            isActive === value.term_id
                              ? `btn btn-brdr ${Style.tabtn} ${Style.active}`
                              : `btn btn-brdr ${Style.tabtn}`
                          }
                        >
                          <span>{value.name}</span>
                        </Button>
                      </li>
                    </>
                  );
                })}
            </ul>
          </div>
        </div>

        <div className={Style.mediaslist}>
          {blogData?.details?.blog_list &&
            blogData?.details?.blog_list.length > 0 &&
            blogDataStatus !== "loading" &&
            blogData?.details?.blog_list.map((value, i) => {
              if (value?.categories_ids == isActive) {
                return (
                  <div key={i}>
                    {value?.categories == "Gallery" ? (
                      <div
                        onClick={() => {
                          setGalleryModal(true);
                          setCurrentIndex(i);
                        }}
                        className={`${Style.newitem} ${Style.gallery}`}
                      >
                        <figure className={Style.postimg}>
                          <img src={value?.post_small_thumbnail_url} alt="" />
                        </figure>
                      </div>
                    ) : (
                      <Link
                        to={`/media-detail/${value?.categories_ids}/${value?.ID}`}
                        className={Style.newitem}
                      >
                        {value?.post_thumbnail_url && (
                          <figure className={Style.postimg}>
                            <img src={value?.post_small_thumbnail_url} alt="" />
                          </figure>
                        )}
                        <div className={Style.details}>
                          <div className={Style.postype}>
                            {value?.categories}
                          </div>
                          <header className={Style.postitle}>
                            {value?.post_title}
                          </header>
                          {!value?.post_thumbnail_url && (
                            <div className={Style.cntsec}>
                              <p>{ReactHtmlParser(value?.post_content)}</p>
                            </div>
                          )}

                          <div className={Style.postdate}>
                            {value?.post_date_beautiful}
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                );
              }
            })}
          {blogData?.details?.blog_list.length < 1 && (
            <span>No More Blogs</span>
          )}
        </div>
        {galleryModal && (
          <ModalGallery
            data={blogData?.details?.blog_list}
            galleryModal={galleryModal}
            setGalleryModal={setGalleryModal}
            index={currentIndex}
          />
        )}

        {/* {blogCatData &&
          blogData?.details?.blog_list.length > 0 &&
          blogData?.details?.more_details?.current_page !=
            blogData?.details?.more_details?.total_pages && (
            <div className={Style.btnwrap}>
              <Button
                className={`btn btn-brdr ${Style.loadmore}`}
                onClick={() => loadMoreclick()}
              >
                <span>Load More</span>
              </Button>
            </div>
          )} */}
      </div>
    </div>
  );
};

export default Media;
