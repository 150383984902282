import React, { useEffect } from "react";
import Menu from "../Menu";
import Footer from "../Footer";

const CommonLayout = ({ children }) => {
  return (
    <div id="viewport">
      <Menu />
      <div id="scroll-container">
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default CommonLayout;
