import React, { useEffect, useRef } from "react";
import Style from "./ServiceFeatures.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const ServiceFeatures = () => {
  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  const triggerThis = useRef();
  const triggertobottom = useRef();

  useEffect(() => {
    gsap.to(triggerThis.current, {
      x: 500,
      y: 500,
      rotate: 20,
      width: 500,
      height: 500,
      opacity: 0.1,
      duration: 6,
      delay: 4,
      scrollTrigger: {
        trigger: triggerThis.current,
        scrub: 4,
      },
    });
    gsap.to(triggerThis.current, {
      x: 100,
      opacity: 0.2,
      duration: 3,
      width: 700,
      height: 300,
      delay: 3,
      filter: "blur(80px)",
      scrollTrigger: {
        trigger: triggertobottom.current,
        scrub: 4,
      },
    });
  }, []);
  return (
    <div
      className={
        loaderClass ? `${Style.featuresec} ${Style.loaded}` : Style.featuresec
      }
    >
      <div className="container">
        <div className={Style.headingsec}>
          {serviceData && <div className={`smtitle `}>Features</div>}
          <h2>
            {
              serviceData?.details[0]?.page_sections?.["service-features"]?.[
                "service-features-main-title"
              ]
            }
          </h2>
        </div>
        <div className={Style.wrapper}>
          <div className={Style.featurelist}>
            <span className={Style.halfcircleshape} ref={triggerThis}></span>

            {serviceData?.details[0]?.page_sections?.["service-features"]?.[
              "service-features-items"
            ] &&
              serviceData?.details[0]?.page_sections?.["service-features"]?.[
                "service-features-items"
              ].length > 0 &&
              serviceData?.details[0]?.page_sections?.["service-features"]?.[
                "service-features-items"
              ].map((value, key) => {
                return (
                  <div key={key}>
                    <div className={Style.featurebox}>
                      <figure className={Style.iconsec}>
                        <img
                          src={value?.["service-features-items-image"]?.url}
                          alt=""
                        />
                      </figure>
                      <header className={Style.titlesec}>
                        {ReactHtmlParser(
                          value?.["service-features-items-title"]
                        )}
                      </header>
                      <div className={Style.shortcnt}>
                        <p>
                          {ReactHtmlParser(
                            value?.["service-features-items-description"]
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}

            <span ref={triggertobottom}></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceFeatures;
