import React from "react";
import Style from "./EnquireDetails.module.scss";
import SocialMedias from "../../SocialMedias";

const EnquireDetails = ({ enquiryData }) => {
  return (
    <div className={Style.equirydetail}>
      <ul>
        <li>
          <span>Toll Free</span>
          <a
            href={`tel:${enquiryData?.details?.["page-more-details"]?.["enquiry-page-details"]?.["banner-toll-free-number"]}`}
          >
            {
              enquiryData?.details?.["page-more-details"]?.[
                "enquiry-page-details"
              ]?.["banner-toll-free-number"]
            }
          </a>
        </li>
        <li>
          <span>Email</span>
          <a
            href={`mailto:${enquiryData?.details?.["page-more-details"]?.["enquiry-page-details"]?.["banner-email"]}`}
          >
            {
              enquiryData?.details?.["page-more-details"]?.[
                "enquiry-page-details"
              ]?.["banner-email"]
            }
          </a>
        </li>
        <li>
          <span>Connect</span>
          <SocialMedias  />
        </li>
      </ul>
    </div>
  );
};

export default EnquireDetails;
