import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import Home from "../../components/Home";

const HomePage = () => {
  return (
    <CommonLayout>
      <Home />
    </CommonLayout>
  );
};

export default HomePage;
