import { useEffect } from "react";
import { getCareersJob } from "../store/slices/careersJobSlice";
import { useDispatch, useSelector } from "react-redux";

export const useCareersJobs = (page) => {
  const dispatch = useDispatch();
  const careersJobData = useSelector((state) => state.careersJob);

  useEffect(() => {
    fetchCarrerJobs(page);
  }, []);

  const fetchCarrerJobs = async (param) => {
    const resultAction = await dispatch(getCareersJob(param));
  };

  return {
    careersJobData: careersJobData?.careersJobData,
    fetchCarrerJobs,
  };
};
