import React from "react";

import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import UsratiBenefit from "../UsratiBenefit";
import ServiceContentSection from "../ServiceContentSection";
import ServiceFeatures from "../ServiceFeatures";
import ServiceFaq from "../ServiceFaq";
import { useService } from "../../logic/useService";
import { useDimensions } from "../../logic/Dimensions";

const Usrati = () => {
  let loc = window.location.pathname.split("/")[2];

  const result = useService(loc);
  const { width } = useDimensions();
  return (
    <>
      <ServiceBanner />
      {width >= 768 && <ServiceBannerFold />}
      <ServiceContentSection />
      <UsratiBenefit />
      <ServiceFeatures />
      <ServiceFaq />
    </>
  );
};

export default Usrati;
