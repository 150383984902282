import React from "react";
import Style from "./MobileFrameVideo.module.scss";
import Assets from "../../Layouts/Assets";

const MobileFrameVideo = () => {
    return (
        <div className={Style.videoplay}>
            <video
            loop
            muted
            autoPlay={true}
            controls=""
            className={Style.videotag}
            playsInline
            >
            <source src={Assets.bnrvideo} type="video/mp4" />
            </video>
        </div>
    )
}

export default MobileFrameVideo;