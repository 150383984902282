import React, { useEffect, useRef, useState } from "react";
import Style from "./ServiceFaq.module.scss";
import Accordion from "react-bootstrap/Accordion";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const ServiceFaq = () => {
  const [activeAccordtionIndex, setActiveAccordtionIndex] = useState(-1);

  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  const faqcircleshape = useRef();
  useEffect(() => {
    gsap.to(faqcircleshape.current, {
      x: 50,
      y: 400,
      duration: 1,
      delay: 0.4,
      scrollTrigger: {
        trigger: faqcircleshape.current,
        scrub: 2,
      },
    });
  },[]);

  const handleSelectAccordion = (e) => {
    const selectedEl = e.target;
    if (selectedEl.classList.contains('collapsed')==true) {
      selectedEl.parentNode.parentNode.classList.add('active')
    }
    else {
      selectedEl.parentNode.parentNode.classList.remove('active')
    }
  };
  return (
    <div
      className={
        loaderClass
          ? `${Style.servicefaqsec} ${Style.loaded}`
          : Style.servicefaqsec
      }
    >
      <span className={Style.circleshape} ref={faqcircleshape}></span>
      <div className="container">
        <div className={Style.headingsec}>
          <h2>
            {serviceData?.details[0]?.page_sections?.faq?.["faq-main-title"]}{" "}
          </h2>
        </div>
        <div className={Style.wrapper}>
          <Accordion
            defaultActiveKey={0}
            alwaysOpen
            className={`accordionview secondary ${Style.accordions}`}
          >
            {serviceData?.details[0]?.page_sections?.faq?.["faq-items"] &&
              serviceData?.details[0]?.page_sections?.faq?.["faq-items"]
                .length > 0 &&
              serviceData?.details[0]?.page_sections?.faq?.["faq-items"].map(
                (value, i) => {
                  return (
                    <Accordion.Item
                      eventKey={i}
                      key={i}
                      onClick={handleSelectAccordion}
                    >
                      <Accordion.Header className={Style.acc_head}>
                        {value?.["faq-question"]}
                      </Accordion.Header>
                      <Accordion.Body className={Style.acc_content}>
                        <p>{value?.["faq-answer"]}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              )}
          </Accordion>

          {/* 
          <div className={Style.btnwrap}>
            <button className={`btn btn-brdr ${Style.morebtn}`}>
              <span>Load More</span>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ServiceFaq;
