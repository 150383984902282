import React, { useEffect, useRef } from "react";
import Style from "./BuyBackTermsAndConditions.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

gsap.registerPlugin(ScrollTrigger);

const BuyBackTermsAndConditions = () => {
  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  const circleone = useRef(),
    circletwo = useRef(),
    ovalshape = useRef(),
    ovaltrigger = useRef(),
    roundrec = useRef();

  useEffect(() => {
    gsap.to(circleone.current, {
      x: -110,
      rotate: 20,
      duration: 1,
      delay: 0.4,
      filter: "blur(60px)",
      scrollTrigger: {
        trigger: circleone.current,
        scrub: 1.5,
      },
    });
    gsap.to(circleone.current, {
      y: 200,
      duration: 0.5,
      delay: 0.4,
      width: 500,
      height: 300,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 1.5,
      },
    });

    gsap.to(circletwo.current, {
      x: -300,
      rotate: 20,
      duration: 1,
      delay: 0.5,
      filter: "blur(60px)",
      scrollTrigger: {
        trigger: circletwo.current,
        scrub: 1.5,
      },
    });
    gsap.to(circletwo.current, {
      y: 300,
      duration: 0.5,
      delay: 3,
      opacity: 0,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 1.5,
      },
    });

    gsap.to(ovalshape.current, {
      x: 100,
      y: 300,
      duration: 1,
      delay: 1.5,
      width: 150,
      height: 150,
      scrollTrigger: {
        trigger: ovaltrigger.current,
        scrub: 3,
      },
    });

    gsap.to(roundrec.current, {
      x: 80,
      y: 10,
      duration: 0.4,
      delay: 0.5,
      height: "150%",
      filter: "blur(80px)",
      opacity: 0.1,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 2,
      },
    });
  });
  return (
    <div
      className={
        loaderClass
          ? `${Style.buybacktermsec} ${Style.loaded}`
          : Style.buybacktermsec
      }
    >
      <span className={Style.circleshape_one} ref={circleone}></span>
      <span className={Style.ovalshape} ref={ovalshape}></span>
      <div className="container">
        <div className={Style.tandcwraper}>
          <span className={Style.roundedrecshape} ref={roundrec}></span>
          <span className={Style.circleshape_two} ref={circletwo}></span>

          <div className={Style.titlesec}>
            <h2>
              {
                serviceData?.details[0]?.page_sections?.[
                  "service-terms-and-conditions"
                ]?.["terms-and-conditions-main-title"]
              }
            </h2>
          </div>

          <div className={Style.tandc}>
            <ul className="list">
              <>
                {ReactHtmlParser(
                  serviceData?.details[0]?.page_sections?.[
                    "service-terms-and-conditions"
                  ]?.["terms-and-conditions-description"]
                )}
              </>
            </ul>
          </div>
          <span ref={ovaltrigger}></span>
        </div>
      </div>
    </div>
  );
};

export default BuyBackTermsAndConditions;
