import React, { useEffect, useRef } from "react";
import Style from "./TermAndConditions.module.scss";
import { Breadcrumb } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTermsAndConditions } from "../../logic/useTermsAndConditions";
import moment from "moment";

gsap.registerPlugin(ScrollTrigger);

const TermAndConditions = () => {
  const { termsData } = useTermsAndConditions(window.location.pathname);

  const termscircleone = useRef(),
    termscircletwo = useRef();
  useEffect(() => {
    gsap.to(termscircleone.current, {
      x: -100,
      y: 900,
      duration: 1,
      delay: 0.4,
      opacity: 0.3,
      scrollTrigger: {
        scrub: 2,
      },
    });
    gsap.to(termscircletwo.current, {
      x: 100,
      y: 600,
      duration: 1,
      delay: 0.4,
      opacity: 0.35,
      scrollTrigger: {
        trigger: termscircletwo.current,
        scrub: 2,
      },
    });
  });
  return (
    <div className={Style.terms_policy}>
      <span className={Style.circleone} ref={termscircleone}></span>
      <span className={Style.circletwo} ref={termscircletwo}></span>
      <div className="container">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {termsData?.details?.post_title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={Style.termsandc_mainhead}>
          <header className={`smtitle ${Style.modified}`}>
            Last Modified:{" "}
            {moment(termsData?.details?.post_modified).format("DD/MM/YYYY")}
          </header>
          <h1>{termsData?.details?.post_title} </h1>
        </div>
        <div className={Style.cntsec}>
          <h2>Overview</h2>
          <p>{ReactHtmlParser(termsData?.details?.post_content)}</p>
        </div>
      </div>
    </div>
  );
};

export default TermAndConditions;
