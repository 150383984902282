import React from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import HomeServicesTab from "../HomeServicesTab";
import ServiceFeatures from "../ServiceFeatures";
import ServiceFaq from "../ServiceFaq";
import { useService } from "../../logic/useService";
import { useSelector } from "react-redux";
import { useDimensions } from "../../logic/Dimensions";

const ValueAddedServices = () => {
  let loc = window.location.pathname.split("/")[2];

  const result = useService(loc);
  const { width } = useDimensions();

  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  return (
    <div>
      <ServiceBanner />
      {width >= 768 && <ServiceBannerFold />}
      <HomeServicesTab
        result={result}
        serviceData={
          serviceData?.details[0].page_sections?.["services-provide"]?.[
            "banner-featured-points-items"
          ]
        }
        loaderClass={loaderClass}
      />
      <ServiceFeatures />
      <ServiceFaq />
    </div>
  );
};

export default ValueAddedServices;
