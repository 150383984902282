import { Button } from "react-bootstrap";
import { useEffect, useState, useRef, useCallback } from "react";
import Style from "./AllBranches.module.scss";
import AllBranch from "../Branches/AllBranch";
import { useBranches } from "../../logic/useBranches";
import { useNearestBranch } from "../../logic/useNearestBranch";
import { useParams } from "react-router-dom";

const AllBranches = ({ currentLocationLoadMore, currentLocation }) => {
  const { postLocation } = useNearestBranch();
    const { branchSlug } = useParams();



  const { branchesData, fetchBranchDetails } = useBranches({
    posts_per_page: 3,
    page: 1,
    pid:branchSlug
  });

  const [pageNumber, setPageNumber] = useState(1);

  const loadMoreclick = () => {
    if (
      branchesData?.more_details?.current_page !=
      branchesData?.more_details?.total_pages
    ) {
      const nextPage = branchesData?.more_details?.current_page + 1;
      setPageNumber(nextPage);
    }
  };

  useEffect(() => {
    if (pageNumber != 1) {
      if (currentLocationLoadMore) {
        postLocation({
          location: currentLocation,
          posts_per_page: 3,
          page: pageNumber,
        });
      } else {
        fetchBranchDetails({
          posts_per_page: 3,
          page: pageNumber,
        });
      }
    }
  }, [pageNumber]);

  return (
    <>
      <div className={Style.allbranches}>
        <div className={Style.branchlist}>
          {branchesData?.details?.map((value, key) => {
            return (
              <div className={Style.fisrto} key={key}>
                <AllBranch value={value} />
              </div>
            );
          })}

          {branchesData?.details?.length < 1 && <span>No Branches</span>}
        </div>

        {branchesData?.more_details?.current_page ===
        branchesData?.more_details?.total_pages ? (
          ""
        ) : (
          <div className="text-center">
            <Button
              className={`btn btn-brdr ${Style.loadmore}`}
              onClick={() => {
                loadMoreclick();
              }}
              id="loadmore"
            >
              <span>Load More</span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AllBranches;
