import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icomoon from "../../IcoMooon";
import Style from "../Menu.module.scss";

const Search = ({
  closeSearch,
  openSearch,
  setActiveSearch,
  searchTerm,
  searchHandleChange,
  searchData,
}) => {
  return (
    <div className={Style.header_search}>
      <button className={Style.header_search_icon} onClick={openSearch}>
        <Icomoon icon={"find"} size={18.59} />
      </button>
      <div className={Style.header_search_wrap}>
        <button className={Style.header_search_icon}>
          <Icomoon icon={"find"} size={18.59} />
        </button>
        <input
          type="text"
          className={Style.header_search_control}
          placeholder={"search"}
          id={"common_search"}
          onBlur={() => {
            searchTerm == "" && setActiveSearch(false);
          }}
          value={searchTerm}
          onChange={(e) => {
            searchHandleChange(e);
          }}
        />
        <button
          className={Style.header_search_icon_close}
          onClick={closeSearch}
        >
          <Icomoon icon={"close"} size={13.29} />
        </button>
      </div>
      {searchData?.data && (
        <div className={Style.header_search_result}>
          {searchData?.data &&
            searchData?.data?.map((value, i) => {
              let indexOfTerm = value?.post_title
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase());
              return (
                <Link
                  to={
                    value?.post_type == "services"
                      ? `/services/${value?.post_name}`
                      : `/${value?.post_name}` && value?.post_type == "branches"
                      ? `/branch-locator/search/${value?.ID}`
                      : `/${value?.post_name}`
                  }
                  className={Style.header_search_result_item}
                  key={i}
                >
                  <span className={Style.link_icon}>
                    <Icomoon icon={"result-icon"} size={14.71} />
                  </span>
                  {indexOfTerm == "-1" ? (
                    <span className={Style.header_search_result_item_text}>
                      {value?.post_title}
                    </span>
                  ) : indexOfTerm == 0 ? (
                    <>
                      <span className={Style.header_search_result_item_text}>
                        {value?.post_title.slice(0, searchTerm.length)}

                        <span>
                          {value?.post_title.slice(
                            searchTerm.length,
                            value?.post_title.length
                          )}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      {/* <span className={Style.header_search_result_item_text}>
                        {value?.post_title.slice(0, indexOfTerm)}
                      </span> */}
                      <span className={Style.header_search_result_item_text}>
                        {value?.post_title.slice(
                          indexOfTerm,
                          searchTerm.length
                        )}

                        <span>
                          {value?.post_title.slice(
                            indexOfTerm + searchTerm.length,
                            value?.post_title.length
                          )}
                        </span>
                      </span>
                    </>
                  )}
                </Link>
              );
            })}

          {searchData?.data?.length < 1 && <span>No Data</span>}
        </div>
      )}
    </div>
  );
};

export default Search;
