import { useEffect } from "react";
import { getCareers } from "../store/slices/careersSlice";
import { useDispatch, useSelector } from "react-redux";

export const useCareers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    careerData();
  }, []);

  const careerData = async () => {
    await dispatch(getCareers({ slug: "careers" }));
  };
};
