import React, { useRef } from "react";
import AboutBanner from "../AboutBanner";
import AboutFinance from "../AboutFinance";
import AboutPartnersAndAssociates from "../AboutPartnersAndAssociates";
import AboutStory from "../AboutStory";
import AboutVisionAndMission from "../AboutVisionAndMission";
import CertificationsTab from "../CertificationsTab";

const AboutUs = () => {
  const aboutRef = useRef(null);

  return (
    <div>
      <AboutBanner aboutRef={aboutRef} />
      {/* <AboutVisionAndMission /> */}
      <AboutFinance aboutRef={aboutRef} />
      <AboutStory />
      <CertificationsTab />
      <AboutPartnersAndAssociates />
    </div>
  );
};

export default AboutUs;
