import React from "react";
import ServiceBanner from "../ServiceBanner";
import CurrencyFold from "../CurrencyFold";
import ServiceExchangeRates from "../ServiceExchangeRates";
import ServiceFeatures from "../ServiceFeatures";
import ServiceFaq from "../ServiceFaq";
import ExchangeSendMoney from "../ExchangeSendMoney";
import { useService } from "../../logic/useService";

const ServiceCurrencyExchange = () => {
  let loc = window.location.pathname.split("/")[2];

  const result = useService(loc);

  return (
    <>
      <ServiceBanner />
      <CurrencyFold />
      <ServiceExchangeRates />
      <ExchangeSendMoney />
      <ServiceFeatures />
      <ServiceFaq />
    </>
  );
};

export default ServiceCurrencyExchange;
