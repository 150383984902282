import { useEffect } from "react";
import { getBlog } from "../store/slices/blogSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "./Dimensions";

export const useBlog = (page) => {
  const dispatch = useDispatch();
  const { blogData, status: blogDataStatus } = useSelector(
    (state) => state.blog
  );
  const { width } = useDimensions();


  useEffect(() => {
    if (width >= 768 && width < 1200) {
      blog({
        posts_per_page: 2,
        page: 1,
        is_home: window.location.pathname == "/" ? true : false,
      });
    } else if (width < 768) {
      blog({
        posts_per_page: 1,
        page: 1,
        is_home: window.location.pathname == "/" ? true : false,
      });
    } else {
      blog({
        posts_per_page: 3,
        page: 1,
        is_home: window.location.pathname == "/" ? true : false,
      });
    }
  }, []);

  const blog = async (params) => {
    await dispatch(getBlog(params));
  };

  return {
    blogData: blogData,
    blog,
    blogDataStatus,
  };
};
