import React, { useState } from "react";
import Assets from "../Layouts/Assets";
import Style from "./CareerForm.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { Dropdown, DropdownButton } from "react-bootstrap";
import * as Yup from "yup";
import { resumeForm } from "../../store/slices/formSlice";
import { useForm } from "../../logic/useForm";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import GoogleRecaptcha from "../Recaptcha";

const CareerForm = () => {

  const dispatch = useDispatch();

  const [captchaReset, setCaptchaReset] = useState(false);
  const [captchaValid, isCaptchaValid] = useState(false);
  const { formKey } = useForm();
  const [value, setValue] = useState("Area of Interest ");
  const [selectedFile, setSelectedFile] = useState("");

  const [successMessage, setSuccessMessage] = useState(false);

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    mobile_number: Yup.string()
      .matches(phoneRegExp, "*Invalid mobile number")
      .required("Mobile number required")
      .min(10, "*Digits in phone number should be equal to 10"),
    interest: Yup.string().required("Area of Interest is required"),
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      mobile_number: "",
      interest: "",
      form_nonce: formKey?.nonce,
      key: formKey?.key,
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (data) => {
      if (captchaValid) {
        formik.resetForm();
        setValue("Area of Interest");

        let newData = {
          ...data,
          file_upload: selectedFile,
        };
        resumeSubmit(newData);

        setSelectedFile("");
        setCaptchaReset(true);
      }
    },
  });

  const changeCaptcha = (value) => {
    if (value) {
      isCaptchaValid(true);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const resumeSubmit = async (data) => {
    var payload = new FormData();
    payload.append("full_name", data.full_name);
    payload.append("email_address", data.email);
    payload.append("mobile_number", data.mobile_number);
    payload.append("upload_your_resume", selectedFile);
    payload.append("area_of_interest", data.interest);
    payload.append("form_nonce", data.form_nonce);
    payload.append("key", data.key);

    const resultAction = await dispatch(resumeForm(payload));

    if (resumeForm.fulfilled.match(resultAction)) {
      setSuccessMessage(resultAction?.payload?.data?.success);
      setTimeout(() => {
        setSuccessMessage(false);
      }, 2000);
     
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={Style.fieldwrapper}>
          <div
            class={`input-holder
               ${formik.values.full_name && `active`} ${
              formik.errors.full_name && formik.touched.full_name ? `error` : ""
            }`}
          >
            <input
              placeholder="Full Name*"
              type="text"
              name="full_name"
              id="full_name"
              class="form-control"
              onChange={formik.handleChange}
              value={formik.values.full_name}
              onBlur={formik.handleBlur}
            />
            <label for="full_name" class="forvalue">
              Full Name*
            </label>

            {formik.touched.full_name && formik.errors.full_name ? (
              <div className="text-danger">{formik.errors.full_name}</div>
            ) : null}
          </div>
          <div>
            <div
              class={`input-holder ${formik.values.email && `active`} ${
                formik.errors.email && formik.touched.email ? `error` : ""
              }`}
            >
              <input
                placeholder="Email Address*"
                type="text"
                name="email"
                id="email"
                class="form-control"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              <label for="email" class="forvalue">
                Email Address*
              </label>
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              class={`input-holder  ${
                formik.values.mobile_number && `active`
              } ${
                formik.errors.mobile_number && formik.touched.mobile_number
                  ? `error`
                  : ""
              }`}
            >
              <input
                placeholder="Mobile Number*"
                type="text"
                name="mobile_number"
                id="mobile_number"
                class="form-control"
                onChange={formik.handleChange}
                value={formik.values.mobile_number}
                onBlur={formik.handleBlur}
                maxLength={10}
              />
              <label for="mobile_number" class="forvalue">
                Mobile Number*
              </label>
              {formik.touched.mobile_number && formik.errors.mobile_number ? (
                <div className="text-danger">{formik.errors.mobile_number}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              class={`input-holder ${formik.values.interest && `active`} ${
                formik.errors.interest && formik.touched.interest ? `error` : ""
              }`}
            >
              <DropdownButton
                className="form-select"
                title={value}
                id="interest"
                name="interest"
                onSelect={(e) => {
                  setValue(e);
                  formik.setFieldValue("interest", e);
                }}
                onChange={formik.handleChange}
                value={formik.values.interest}
                onBlur={formik.handleBlur}
              >
                <Dropdown.Item eventKey="Complaint" id="Complaint">
                  Complaint
                </Dropdown.Item>
                <Dropdown.Item eventKey="Enquiry" id="Enquiry">
                  Enquiry
                </Dropdown.Item>
                <Dropdown.Item eventKey="Suggestion" id="Suggestion">
                  Suggestion
                </Dropdown.Item>
              </DropdownButton>
              {formik.touched.interest && formik.errors.interest ? (
                <div className="text-danger">{formik.errors.interest}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              class={`input-holder ${formik.values.file_upload && `active`} ${
                formik.errors.file_upload && formik.touched.file_upload
                  ? `error`
                  : ""
              }`}
            >
              <div className="uploadwraper">
                <div className="uploadlabel">
                  <label for="file_upload" className="fileupload">
                    <IcoMoon icon="attach" size={15} />
                    <span>
                      {selectedFile?.name
                        ? selectedFile?.name
                        : "Upload Your Resume"}
                    </span>
                    <input
                      type="file"
                      name="file_upload"
                      id="file_upload"
                      class="file-control"
                      onChange={handleFileSelect}
                    />
                  </label>
                </div>
                <div className="filetypes">
                  <p>File Type: (PDF, DOC, DOCX, JPEG, PNG) Under 7MB.</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="input-holder">
              <GoogleRecaptcha
                onChange={changeCaptcha}
                captchaReset={captchaReset}
              />
            </div>
          </div>
          <div>
            <div class="input-holder">
              <button
                type="submit"
                className={`btn btn-primary ${Style.submitbtn}`}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
          <span>{successMessage ? "The Form was submitted successfully" : ""}</span>
        </div>
      </form>
    </>
  );
};

export default CareerForm;
