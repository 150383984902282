import { useEffect } from "react";
import { getBlogCat } from "../store/slices/blogCategorySlice";
import { useDispatch, useSelector } from "react-redux";

export const useBlogCategory = (params) => {
  const dispatch = useDispatch();
  const blogCatData = useSelector((state) => state.blogCat);

  useEffect(() => {
    blogCat();
  }, []);

  const blogCat = async () => {
    await dispatch(getBlogCat());
  };

  return {
    blogCatData: blogCatData?.blogCatData,
  };
};
