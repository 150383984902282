import React from "react";
import Style from "../Footer.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const FooterBottom = () => {
  const { footerData } = useSelector((state) => state.footer);

  return (
    <div className={Style.ftr_btm}>
      <div className={Style.btm_strip}>
        <ul>
          <li>{footerData?.details?.footer_more_details?.copyright}</li>

          {footerData && (
            <li>
              <>
                <Link to="/terms-conditions">Term and Conditions</Link>
              </>
              <>
                <span className={Style.vbar}>|</span>
              </>
              <>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </>
            </li>
          )}
          {footerData && (
            <li>
              Designed by:{" "}
              <a href={'https://webandcrafts.com/'} target="_blank">Webandcrafts</a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default FooterBottom;
