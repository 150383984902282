import React from "react";
import { Link } from "react-router-dom";
import Style from "./ServiceContentTwo.module.scss";
import ReactHtmlParser from "react-html-parser";

const ServiceContentTwo = ({ serviceData, loaderClass }) => {
  let data =
    serviceData?.details[0]?.page_sections?.["page-details-section-one"]?.[
      "page-details-section-one-items"
    ]?.[1];

  return (
    <>
      <div
        className={
          loaderClass ? `${Style.textwrap} ${Style.loaded}` : Style.textwrap
        }
      >
        <header className={`smtitle ${Style.smtitle}`}>
          {data?.["page-details-section-small-title"]}
        </header>
        <h2>{data?.["page-details-section-one-title"]}</h2>
        <ul className="list">
          <p>
            {ReactHtmlParser(data?.["page-details-section-one-description"])}
          </p>
        </ul>
        {loaderClass && (
          <Link to="/" className={`btn btn-brdr ${Style.btn}`}>
            <span>
              {
                serviceData?.details[0]?.page_sections?.["banner-field-set"]?.[
                  "banner-field-set-button-text"
                ]
              }
            </span>
          </Link>
        )}
      </div>
    </>
  );
};
export default ServiceContentTwo;
