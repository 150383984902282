import React from "react";
import { Link } from "react-router-dom";
import Assets from "../Layouts/Assets";
import Style from "./ServiceBankTransfer.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const ServiceBankTransfer = () => {
  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  return (
    <div
      className={
        loaderClass
          ? `${Style.banktrasfersec} ${Style.loaded}`
          : Style.banktrasfersec
      }
    >
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.textcol}>
            <div className={Style.textwrap}>
              <header className={`smtitle ${Style.smtitle}`}>
                {
                  serviceData?.details[0]?.page_sections?.[
                    "page-details-section-one"
                  ]?.["page-details-section-one-items"]?.[0]?.[
                    "page-details-section-small-title"
                  ]
                }
              </header>
              <h2>
                {
                  serviceData?.details[0]?.["page_sections"]?.[
                    "page-details-section-one"
                  ]?.["page-details-section-one-items"]?.[0]?.[
                    "page-details-section-one-title"
                  ]
                }
              </h2>
              <p>
                {ReactHtmlParser(
                  serviceData?.details[0]?.["page_sections"]?.[
                    "page-details-section-one"
                  ]?.["page-details-section-one-items"]?.[0]?.[
                    "page-details-section-one-description"
                  ]
                )}
              </p>

              {loaderClass && (
                <Link
                  to="/services/currency-exchange"
                  className={`btn btn-brdr ${Style.btn}`}
                >
                  <span>
                    {
                      serviceData?.details[0]?.["page_sections"]?.[
                        "page-details-section-one"
                      ]?.["page-details-section-one-items"]?.[0][
                        "page-details-section-one-button-text"
                      ]
                    }
                  </span>
                </Link>
              )}
            </div>
          </div>
          <div className={Style.imgcol}>
            <div className={Style.devicewrap}>
              <figure className={Style.imgwrap}>
                <div className={Style.imgsec}>
                  <img src={Assets.appview_01} />
                </div>
              </figure>
              <div className={`${Style.trasferoute} bounceanim`}>
                <div className={Style.box}>
                  <header>Your transfer route</header>
                  <div className={Style.fromto}>
                    <div className={Style.from}>
                      <span className={Style.icon}>
                        <IcoMoon icon="AE" size={22} />
                      </span>
                      <span>AED</span>
                    </div>
                    <span className={Style.toarw}>
                      <IcoMoon icon="arrow-right" size={22} />
                    </span>
                    <div className={Style.to}>
                      <span className={Style.icon}>
                        <IcoMoon icon="IN" size={22} />
                      </span>
                      <span>INR</span>
                    </div>
                  </div>
                  <div className={Style.timelaps}>
                    <header>Should arrive</header>
                    <div className={Style.hours}>Within 24 to 72 hours</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBankTransfer;
