import React, { useState } from "react";
import Assets from "../Layouts/Assets";
import Style from "./ApplyNowForm.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { Dropdown, DropdownButton } from "react-bootstrap";
import * as Yup from "yup";
import { useForm } from "../../logic/useForm";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { applyNowForm } from "../../store/slices/formSlice";
import GoogleRecaptcha from "../Recaptcha";

const ApplyNowForm = ({ applyNowRef }) => {
  const [message, setMessage] = useState(false);
  const { formKey } = useForm();

  const [captchaReset, setCaptchaReset] = useState(false);

  const [captchaValid, isCaptchaValid] = useState(false);
  const [experience, setExperience] = useState("Years Of Experience");

  const [selectedFile, setSelectedFile] = useState("");

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),
    education: Yup.string().required("Education is required"),
    years_of_experience: Yup.string().required("Experience is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    apply_mobile_number: Yup.string()
      .matches(phoneRegExp, "Invalid mobile number")
      .required("Mobile number required")
      .min(10, "Digits in phone number should be equal to 10"),
    years_of_experience: Yup.string().required("Experience is required"),
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      apply_mobile_number: "",
      years_of_experience: "",
      education: "",

      form_nonce: formKey?.nonce,
      key: formKey?.key,
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (data) => {
      if (captchaValid) {
        formik.resetForm();
        setExperience("Years Of Experience");
        let newData = {
          ...data,
          files: selectedFile,
        };
        applyNowSubmit(newData);
        setSelectedFile("");
        setCaptchaReset(true);
      }
    },
  });

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const changeCaptcha = (value) => {
    if (value) {
      isCaptchaValid(true);
    }
  };

  const dispatch = useDispatch();

  const applyNowSubmit = async (data) => {
    var payload = new FormData();
    payload.append("full_name", data.full_name);
    payload.append("email_address", data.email);
    payload.append("mobile_number", data.apply_mobile_number);
    payload.append("years_of_experience", data.years_of_experience);
    payload.append("education", data.education);
    payload.append("upload_your_resume", selectedFile);
    payload.append("form_nonce", data.form_nonce);
    payload.append("key", data.key);

    const resultAction = await dispatch(applyNowForm(payload));

    if (applyNowForm.fulfilled.match(resultAction)) {
      setMessage(resultAction?.payload?.data?.success);
      setTimeout(() => {
        setMessage(false);
      }, 2500);
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={Style.fieldwrapper} ref={applyNowRef}>
        <div>
          <div
            class={`input-holder  ${formik.values.full_name && `active`} ${
              formik.errors.full_name && formik.touched.full_name ? `error` : ""
            }`}
          >
            <input
              placeholder=" Full Name*"
              type="text"
              name="full_name"
              id="full_name"
              class="form-control"
              onChange={formik.handleChange}
              value={formik.values.full_name}
              onBlur={() => {
                formik.handleBlur();
              }}
            />
            <label for="full_name" class="forvalue">
              Full Name*
            </label>

            {formik.touched.full_name && formik.errors.full_name ? (
              <div className="text-danger">{formik.errors.full_name}</div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            class={`input-holder ${formik.values.email && `active`} ${
              formik.errors.email && formik.touched.email ? `error` : ""
            } `}
          >
            <input
              placeholder="  Email Address*"
              type="text"
              name="email"
              id="email"
              class="form-control"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={() => {
                formik.handleBlur();
              }}
            />
            <label for="email" class="forvalue">
              Email Address*
            </label>
            {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            class={`input-holder ${
              formik.values.apply_mobile_number && `active`
            } ${
              formik.errors.apply_mobile_number &&
              formik.touched.apply_mobile_number
                ? `error`
                : ""
            } `}
          >
            <input
              placeholder="Mobile Number*"
              type="text"
              name="apply_mobile_number"
              id="apply_mobile_number"
              class="form-control"
              onChange={formik.handleChange}
              value={formik.values.apply_mobile_number}
              onBlur={() => {
                formik.handleBlur();
              }}
              maxLength={10}
            />
            <label for="apply_mobile_number" class="forvalue">
              Mobile Number*
            </label>
            {formik.touched.apply_mobile_number &&
            formik.errors.apply_mobile_number ? (
              <div className="text-danger">
                {formik.errors.apply_mobile_number}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            class={`input-holder ${
              formik.values.years_of_experience && `active`
            } ${
              formik.errors.years_of_experience &&
              formik.touched.years_of_experience
                ? `error`
                : ""
            }`}
          >
            <DropdownButton
              className="form-select"
              title={experience}
              id="years_of_experience"
              name="years_of_experience"
              onSelect={(e) => {
                setExperience(e);
                formik.setFieldValue("years_of_experience", e);
              }}
              onChange={formik.handleChange}
              value={formik.values.years_of_experience}
              onBlur={() => {
                formik.handleBlur();
              }}
            >
              <Dropdown.Item eventKey="1" id="1">
                1
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" id="2">
                2
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" id="3">
                3
              </Dropdown.Item>
              <Dropdown.Item eventKey="4" id="4">
                4
              </Dropdown.Item>
            </DropdownButton>
            {formik.touched.years_of_experience &&
            formik.errors.years_of_experience ? (
              <div className="text-danger">
                {formik.errors.years_of_experience}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            class={`input-holder ${formik.values.education && `active`} ${
              formik.errors.education && formik.touched.education ? `error` : ""
            }`}
          >
            <input
              placeholder="Education*"
              type="text"
              name="education"
              id="education"
              class="form-control"
              onChange={formik.handleChange}
              value={formik.values.education}
              onBlur={() => {
                formik.handleBlur();
              }}
              maxLength={10}
            />
            <label for="education" class="forvalue">
              Education*
            </label>
            {formik.touched.education && formik.errors.education ? (
              <div className="text-danger">{formik.errors.education}</div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            class={`input-holder ${formik.values.file_upload && `active`} ${
              formik.errors.file_upload && formik.touched.file_upload
                ? `error`
                : ""
            }`}
          >
            <div className="uploadwraper">
              <div className="uploadlabel">
                <label for="file_upload" className="fileupload">
                  <IcoMoon icon="attach" size={15} />
                  <span>
                    {selectedFile?.name
                      ? selectedFile?.name
                      : "Upload Your Resume"}
                  </span>
                </label>
              </div>
              <div className="filetypes">
                <p>File Type: (PDF, DOC, DOCX, JPEG, PNG) Under 7MB.</p>
              </div>
            </div>
            <input
              type="file"
              name="files"
              id="file_upload"
              class="file-control"
              onChange={handleFileSelect}
            />
          </div>
        </div>
        <div>
          <div class="input-holder">
            <GoogleRecaptcha
              onChange={changeCaptcha}
              captchaReset={captchaReset}
            />
          </div>
        </div>
        <div>
          <div class="input-holder">
            <input
              type="submit"
              className={`btn btn-primary ${Style.submitbtn}`}
              value="Submit Your Application"
            />
          </div>
        </div>

        {message && (
          <div className={`${Style.success_messsage} success_messsage`}>
            <div>The Form was submitted successfully</div>
          </div>
        )}
      </div>
    </form>
  );
};

export default ApplyNowForm;
