import { useEffect, useState } from "react";
import { getTerms } from "../store/slices/termsAndConditionsSlice";
import { useDispatch, useSelector } from "react-redux";

export const useTermsAndConditions = (link) => {
  const dispatch = useDispatch();
  const termsData = useSelector((state) => state.terms);

  useEffect(() => {
    const path = link.split("/");
    terms(path[1]);
  }, [link]);

  const terms = async (path) => {
    await dispatch(getTerms({ slug: path }));
  };

  return {
    termsData: termsData?.termsData,
  };
};
