import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  currencyRatesData: null,
  currencyTableRatesData: null,
};

export const getCurrencyRates = createAsyncThunk(
  "currencyRates/getCurrencyRates",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`currency-exchange-rates`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCurrencyTableRates = createAsyncThunk(
  "currencyTableRates/getCurrencyTableRates",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`currency-exchange-table-rates`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const currencyRatesSlice = createSlice({
  name: "currencyRates",
  initialState,
  reducers: {},
  extraReducers: {
    [getCurrencyRates.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCurrencyRates.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.currencyRatesData = action.payload?.data;
    },
    [getCurrencyRates.rejected]: (state, action) => {
      state.status = "failed";
    },



    [getCurrencyTableRates.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCurrencyTableRates.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.currencyTableRatesData = action.payload?.data;
    },
    [getCurrencyTableRates.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default currencyRatesSlice.reducer;
