import React, { useEffect, useRef, useState } from "react";
import Style from "./CareerResume.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import CareerForm from "../CareerForm";
import Assets from "../Layouts/Assets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

gsap.registerPlugin(ScrollTrigger);

const CareerResume = ({ dropRef }) => {
  const { careersData, isActive } = useSelector((state) => state.careers);

  const circleone = useRef(),
    circletwo = useRef(),
    ovalshape = useRef(),
    ovaltrigger = useRef(),
    roundrec = useRef();
  useEffect(() => {
    gsap.to(circleone.current, {
      x: -110,
      rotate: 20,
      duration: 1,
      delay: 0.4,
      filter: "blur(60px)",
      opacity: 0.02,
      scrollTrigger: {
        trigger: circleone.current,
        scrub: 1.5,
      },
    });
    gsap.to(circleone.current, {
      y: 200,
      duration: 0.5,
      delay: 0.4,
      width: 500,
      height: 300,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 1.5,
      },
    });

    gsap.to(circletwo.current, {
      x: -200,
      rotate: 20,
      duration: 1,
      delay: 0.5,
      filter: "blur(60px)",
      opacity: 0.2,
      scrollTrigger: {
        trigger: circletwo.current,
        scrub: 1.5,
      },
    });
    gsap.to(circletwo.current, {
      y: 100,
      duration: 0.5,
      delay: 3,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 1.5,
      },
    });

    gsap.to(ovalshape.current, {
      x: 100,
      y: 300,
      duration: 1,
      delay: 1.5,
      width: 150,
      height: 150,
      scrollTrigger: {
        trigger: ovaltrigger.current,
        scrub: 3,
      },
    });

    gsap.to(roundrec.current, {
      y: 10,
      duration: 0.4,
      delay: 0.5,
      height: "110%",
      filter: "blur(80px)",
      opacity: 0.1,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 2,
      },
    });
  });

  const handleScrollToPosition = () => {
    let elem = document.querySelector(`.jobSearchSec`);
    window.scrollTo({
      top: elem.offsetHeight - 120,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={
        isActive
          ? `${Style.dropresumesec} ${Style.loaded}`
          : Style.dropresumesec
      }
      ref={dropRef}
    >
      <span className={Style.circleshape_one} ref={circleone}></span>
      <span className={Style.ovalshape} ref={ovalshape}></span>
      <div className="container">
        <div className={Style.outerwrap}>
          <span className={Style.roundedrecshape} ref={roundrec}></span>
          <span className={Style.circleshape_two} ref={circletwo}></span>
          <div className={`row ${Style.wrapper}`}>
            <div className="col-md-6 d-none d-md-block">
              {/* Hide This After 767.98px */}
              <div className={Style.textwrap}>
                <h2>
                  {
                    careersData?.details?.page_sections?.[
                      "drop-your-resume-field-set"
                    ]?.["drop-your-resume-field-set-main-text"]
                  }
                </h2>
                <p>
                  {ReactHtmlParser(
                    careersData?.details?.page_sections?.[
                      "drop-your-resume-field-set"
                    ]?.["drop-your-resume-field-set-main-description"]
                  )}
                </p>
                {careersData && (
                  <div
                    onClick={() => handleScrollToPosition()}
                    className={`arw-link ${Style.arw_link}`}
                  >
                    <span>
                      <IcoMoon icon="arrow-right" size={12} />
                    </span>
                    {
                      careersData?.details?.page_sections?.[
                        "drop-your-resume-field-set"
                      ]?.["drop-your-resume-field-set-button-text"]
                    }{" "}
                  </div>
                )}
              </div>
              <div className={`dropbox ${Style.dropbox}`}>
                {careersData && (
                  <label htmlfor="" className="droplabel">
                    <figure className="dropthumb">
                      <img src={Assets.drop_file} alt="" />
                    </figure>
                  </label>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className={Style.mbleview}>
                {/* Only Show This After 767.98px */}
                <div className={`smtitle ${Style.smtitle}`}>
                  Drop Your Resume
                </div>
                <h2>
                  Come Reimagine <br />
                  With Us
                </h2>
                <div
                  onClick={() => handleScrollToPosition()}
                  className={`arw-link ${Style.arw_link}`}
                >
                  <span>
                    <IcoMoon icon="arrow-right" size={12} />
                  </span>
                  Find Open Positions
                </div>
              </div>
              <div className={Style.formouterwrap}>
                <CareerForm />
              </div>
            </div>
          </div>
          <span ref={ovaltrigger}></span>
        </div>
      </div>
    </div>
  );
};

export default CareerResume;
