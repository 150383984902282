import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import IcoMoon from "../Layouts/IcoMooon";
import CareerJobList from "./CareerJobList";
import CareerJobMobileView from "./CareerJobMobileView";
import Style from "./CareerJobSearch.module.scss";
import { useCareersJobs } from "../../logic/useCareersJobs";
import * as _ from "lodash";
import { getCareersJob } from "../../store/slices/careersJobSlice";
import { useDimensions } from "../../logic/Dimensions";

const CareerJobSearch = () => {
  const { width } = useDimensions();

  const dispatch = useDispatch();

  const { careersData, isActive } = useSelector((state) => state.careers);

  const { careersJobData } = useCareersJobs({ posts_per_page: "4", page: "1" });

  const [depTitle, setDepTitle] = useState("All Departments");
  const [locTitle, setLocTitle] = useState("All Locations");

  const departmentChange = async (e) => {
    if (e !== "Department") {
      const dep_data = _.find(careersJobData?.details?.departments, {
        term_id: parseInt(e),
      });

      setDepTitle(dep_data.name);
      await dispatch(getCareersJob({ d_id: dep_data?.term_id }));
    } else {
      setDepTitle("All Department");
      await dispatch(getCareersJob({ posts_per_page: "2", page: 1 }));
    }
  };

  const locationChange = async (e) => {
    if (e !== "All") {
      const loc_data = _.find(careersJobData?.details?.locations, {
        term_id: parseInt(e),
      });

      setLocTitle(loc_data.name);
      await dispatch(getCareersJob({ l_id: loc_data?.term_id }));
    } else {
      setLocTitle("All Location");
      await dispatch(getCareersJob({ posts_per_page: "2", page: 1 }));
    }
  };

  return (
    <div
      className={`${
        isActive
          ? `${Style.jobresultsec} ${Style.loaded}`
          : `${Style.jobresultsec}`
      } jobSearchSec`}
    >
      <div className="container ">
        <div className={Style.titledropdownsec}>
          <h2>
            {
              careersData?.details?.page_sections?.["job-serach-field-set"]?.[
                "job-serach-field-set-main-text"
              ]
            }
          </h2>

          {careersJobData && (
            <div className={Style.dropdownwrap}>
              <DropdownButton
                id="department-dropdown"
                className={`${Style.dropdown} `}
                title={depTitle}
                onSelect={(e) => {
                  departmentChange(e);
                }}
              >
                <Dropdown.Item eventKey="Department">
                  All Department
                </Dropdown.Item>

                {careersJobData?.details?.departments &&
                  careersJobData?.details?.departments.length > 0 &&
                  careersJobData?.details?.departments.map((value, key) => {
                    return (
                      <Dropdown.Item eventKey={value.term_id} key={key}>
                        {value?.name}
                      </Dropdown.Item>
                    );
                  })}
              </DropdownButton>
              <DropdownButton
                id="department-dropdown"
                className={Style.dropdown}
                title={locTitle}
                onSelect={(e) => {
                  locationChange(e);
                }}
              >
                <Dropdown.Item eventKey="All">All Location</Dropdown.Item>
                {careersJobData?.details?.locations &&
                  careersJobData?.details?.locations.length > 0 &&
                  careersJobData?.details?.locations.map((value, key) => {
                    return (
                      <Dropdown.Item eventKey={value.term_id} key={key}>
                        {value?.name}
                      </Dropdown.Item>
                    );
                  })}
              </DropdownButton>
            </div>
          )}
        </div>
        <CareerJobList careersJobData={careersJobData} />
        {width < 768 && <CareerJobMobileView />}
      </div>
    </div>
  );
};

export default CareerJobSearch;
