import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import RatesDesktopView from "./RatesDesktopView";
import RatesMobileView from "./RatesMobileView";
import Style from "./ServiceExchangeRates.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { useDispatch } from "react-redux";
import { getCurrencyTableRates } from "../../store/slices/currencyRatesSlice";
import { useCurrencyTableRates } from "../../logic/useCurrencyTableRates";
import axios from "axios";

const ServiceExchangeRates = () => {
  const { currencyTableRatesData } = useCurrencyTableRates();

  const dispatch = useDispatch();

  const [APIData, setAPIData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = APIData?.details.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(APIData);
    }
  };

  useEffect(() => {
    searchCurrency()
}, [])

  const searchCurrency = async () => {
   const resultAction= await dispatch(getCurrencyTableRates());
   if (getCurrencyTableRates.fulfilled.match(resultAction)) {
    setAPIData(resultAction?.payload?.data);
  }
  };

  return (
    <div className={Style.todaysratesec}>
      <div className="container">
        <div className={Style.title_search}>
          <div className={Style.titlesec}>
            <h2>Today’s exchange rates</h2>
          </div>
          <div className={`d-none d-md-block ${Style.search}`}>
            <div className={Style.searcholder}>
              <span className={Style.searchicon}>
                <IcoMoon icon="find" size={16.6} />
              </span>
              <input
                className={Style.searchinput}
                type="text"
                id="search_currency"
                placeholder={"Search Currency"}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <RatesDesktopView
        searchInput={searchInput}
        filteredResults={filteredResults}
        currencyTableRatesData={currencyTableRatesData}
        APIData={APIData}
      />
      <RatesMobileView />
    </div>
  );
};

export default ServiceExchangeRates;
