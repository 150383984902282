import Style from "./WhatsNew.module.scss";
import { Link, useNavigate } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import { useBlog } from "../../logic/useBlog";
import { useDimensions } from "../../logic/Dimensions";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactHtmlParser from "react-html-parser";

gsap.registerPlugin(ScrollTrigger);

const WhatsNew = () => {
  const { width } = useDimensions();
  const { blogData, blogDataStatus } = useBlog();

  let navigate = useNavigate();

  return (
    <div className={`${Style.whatsnewsec} ${Style.loaded}`}>
      <div className="container ">
        <div className={Style.secheader}>
          <header className={Style.sectitle}>
            <h2>Whats New</h2>
          </header>
          {width >= 768 && (
            <Link to="/media" className={`arw-link ${Style.viewall}`}>
              <span>
                <IcoMoon icon="arrow-right" size={12} />
              </span>
              View All
            </Link>
          )}
        </div>

        <div className={`${Style.postlistwrap} blog-el`}>
          {blogData?.details?.blog_list &&
            blogData?.details?.blog_list.length > 0 &&
            blogDataStatus !== "loading" &&
            blogData?.details?.blog_list.map((value, i) => {
              return (
                <div key={i} className={"blog-el"}>
                  {value?.categories !== "Gallery" && (
                    <Link
                      to={`/media-detail/${value?.categories_ids}/${value?.ID}`}
                      className={Style.newitem}
                    >
                      {value?.post_thumbnail_url && (
                        <figure className={Style.postimg}>
                          <img src={value?.post_small_thumbnail_url} alt="" />
                        </figure>
                      )}
                      <div className={Style.details}>
                        <div className={Style.postype}>{value?.categories}</div>
                        <header className={Style.postitle}>
                          {value?.post_title}
                        </header>
                        {!value?.post_thumbnail_url && (
                          <div className={Style.cntsec}>
                            <p>{ReactHtmlParser(value?.post_content)}</p>
                          </div>
                        )}

                        <div className={Style.postdate}>
                          {value?.post_date_beautiful}
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              );
            })}
        </div>

        {width < 768 && (
          <div className={Style.btnwrap}>
            <Link className={`btn btn-brdr ${Style.btn}`} to="/media">
              <span>View All</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default WhatsNew;
