import React from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceContentSection from "../ServiceContentSection";
import BuyBackTermsAndConditions from "../BuyBackTermsAndConditions";
import ServiceFeatures from "../ServiceFeatures";
import ServiceFaq from "../ServiceFaq";
import { useService } from "../../logic/useService";
import { useDimensions } from "../../logic/Dimensions";

const BuyBackGuarantee = () => {
  let loc=window.location.pathname.split("/")[2]

  const result = useService(loc);
  const {width} = useDimensions();
  return (
    <div>
      <ServiceBanner  />
      {width >= 768 && <ServiceBannerFold />}
      <ServiceContentSection  />
      <BuyBackTermsAndConditions  />
      <ServiceFeatures  />
      <ServiceFaq  />

    </div>
  );
};

export default BuyBackGuarantee;
