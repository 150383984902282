import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Style from "./HomeAboutUs.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useHomeAboutUs } from "../../logic/useHomeAboutUs";
import { useInView } from "react-intersection-observer";
gsap.registerPlugin(ScrollTrigger);

const HomeAboutUs = () => {
  const { aboutData } = useHomeAboutUs();

  const circleone = useRef(),
    circletwo = useRef(),
    ovalshape = useRef(),
    ovaltrigger = useRef(),
    roundrec = useRef();

  useEffect(() => {
    gsap.to(circleone.current, {
      x: -110,
      rotate: 20,
      duration: 1,
      delay: 0.4,
      filter: "blur(60px)",
      scrollTrigger: {
        trigger: circleone.current,
        scrub: 1.5,
      },
    });
    gsap.to(circleone.current, {
      y: 200,
      duration: 0.5,
      delay: 0.4,
      width: 500,
      height: 300,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 1.5,
      },
    });

    gsap.to(circletwo.current, {
      x: -300,
      rotate: 20,
      duration: 1,
      delay: 0.5,
      filter: "blur(60px)",
      scrollTrigger: {
        trigger: circletwo.current,
        scrub: 1.5,
      },
    });
    gsap.to(circletwo.current, {
      y: 300,
      duration: 0.5,
      delay: 3,
      opacity: 0,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 1.5,
      },
    });

    gsap.to(ovalshape.current, {
      x: 150,
      y: 400,
      duration: 3,
      delay: 1,
      width: 300,
      height: 300,
      filter: "blur(70px)",
      scrollTrigger: {
        trigger: ovaltrigger.current,
        scrub: 3,
      },
    });

    gsap.to(roundrec.current, {
      y: 10,
      duration: 0.4,
      delay: 0.5,
      height: "105%",
      filter: "blur(80px)",
      opacity: 0.2,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 2,
      },
    });
  });

  const [ref1, inView1] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 0,
    triggerOnce: true,
  });


  return (
    <div className={`${Style.aboutusec} ${Style.loaded}`}>
      <span className={Style.circleshape_one} ref={circleone}></span>
      <span className={Style.ovalshape} ref={ovalshape}></span>
      <div className="container">
        <div className={Style.wrapper}>
          <span className={Style.roundedrecshape} ref={roundrec}></span>
          <span className={Style.circleshape_two} ref={circletwo}></span>

          <header className={`sectitle ${Style.sectitle} `}>
            <h2 ref={ref1} className={`anim`}>
              {aboutData?.details["home-about-us-main-title"]}
            </h2>
          </header>
          <div className={Style.shortcnt}>
            <p ref={ref2} className={`anim`}>
              {aboutData?.details["home-about-us-main-description"]}
            </p>
          </div>

          <div className={Style.countsec}>
            {aboutData?.details["home-about-us-status-updates"] &&
              aboutData?.details["home-about-us-status-updates"].length > 0 &&
              aboutData?.details["home-about-us-status-updates"].map(
                (value, key) => {
                  return (
                    <div key={key} className={"aboutUs-el"}>
                      <div className={Style.count}>{value.count}</div>
                      <header className={Style.itemtitle}>{value.Title}</header>
                    </div>
                  );
                }
              )}
          </div>

          <Link to="/about-us" className={`btn btn-brdr ${Style.aboutbtn}`}>
            <span>Know More About Us</span>
          </Link>
        </div>
      </div>
      <span ref={ovaltrigger}></span>
    </div>
  );
};

export default HomeAboutUs;
