import React, { useRef } from "react";
import CareerBanner from "../CareerBanner";
import CareerCultureAndValues from "../CareerCultureAndValues";
import CareerFuture from "../CareerFuture";
import CareerJobSearch from "../CareerJobSearch";
import CareerResume from "../CareerResume";
import { useCareers } from "../../logic/useCareers";

import { useDimensions } from "../../logic/Dimensions";

const Careers = () => {
  const result = useCareers();
  const { width } = useDimensions();

  const dropRef = useRef(null);

  return (
    <div>
      <CareerBanner />
      <CareerJobSearch />
      <CareerCultureAndValues dropRef={dropRef} />
      {width >= 768 && <CareerFuture />}
      <CareerResume dropRef={dropRef} />
    </div>
  );
};

export default Careers;
