import React from "react";
import { Link } from "react-router-dom";
import Style from "./ServiceContent.module.scss";
import ReactHtmlParser from "react-html-parser";

const ServiceContent = ({ serviceData, loaderClass, btnData }) => {
  return (
    <>
      <div
        className={
          loaderClass ? `${Style.textwrap} ${Style.loaded}` : Style.textwrap
        }
      >
        <header className={`smtitle ${Style.smtitle}`}>
          {serviceData?.["page-details-section-small-title"]}
        </header>
        <h2>{serviceData?.["page-details-section-one-title"]}</h2>
        <ul className="list">
          <p>
            {ReactHtmlParser(
              serviceData?.["page-details-section-one-description"]
            )}
          </p>
        </ul>
        {/* {loaderClass && (
          <Link to="/" className={`btn btn-primary ${Style.btn}`}>
            <span>
              {btnData?.["banner-field-set"]?.["banner-field-set-button-text"]}
            </span>
          </Link>
        )} */}
      </div>
    </>
  );
};
export default ServiceContent;
