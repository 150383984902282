import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Style from "./Menu.module.scss";
import Assets from "../Assets";
import DesktopNav from "./DesktopNav";
import MobileMenu from "./MobileMenu";
import { useSelector } from "react-redux";
import { useSearch } from "../../../logic/useSearch";

import { useDimensions } from "../../../logic/Dimensions";
import Search from "./Search";

const Menu = () => {
  const {
    activeSearch,
    setActiveSearch,
    openSearch,
    closeSearch,
    searchTerm,
    searchHandleChange,
    searchData,
    setSearchTerm,
  } = useSearch();

  const { width } = useDimensions();

  const [active, setActive] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setActive(Style.loaded);
    }, 1000);
  }, []);

  const menuSelect = () => {
    document.body.classList.remove("show-menu");
  };

  const { isActive, menuData } = useSelector((state) => state.menu);

  const site_logo = menuData?.site_logo?.url;

  return (
    <div className={`${Style.siteheader} ${Style.loaded}`}>
      <div className={`container ${Style.siteheader_container}`}>
        <div className={Style.headerwrap}>
          <div className={Style.logosec}>
            {isActive ? (
              <Link to="/" onClick={menuSelect}>
                <img src={site_logo} />
              </Link>
            ) : (
              <Link to="/" onClick={menuSelect}>
                <img src={Assets.logo} />
              </Link>
            )}
          </div>
          <div
            className={
              activeSearch
                ? `${Style.headeright} search_open`
                : Style.headeright
            }
          >
            {width < 1200 && (
              <Search
                closeSearch={closeSearch}
                openSearch={openSearch}
                searchTerm={searchTerm}
                searchHandleChange={searchHandleChange}
                searchData={searchData}
                setSearchTerm={setSearchTerm}
                setActiveSearch={setActiveSearch}
              />
            )}
            <DesktopNav
              activeSearch={activeSearch}
              closeSearch={closeSearch}
              openSearch={openSearch}
              setActiveSearch={setActiveSearch}
              searchTerm={searchTerm}
              searchHandleChange={searchHandleChange}
              searchData={searchData}
              setSearchTerm={setSearchTerm}
            />
            <MobileMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
