import React, { useState } from "react";
import Style from "./ModalForm.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import Assets from "../Layouts/Assets";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useForm } from "../../logic/useForm";
import { customerForm } from "../../store/slices/formSlice";
import GoogleRecaptcha from "../Recaptcha";
import { values } from "lodash";

const ModalForm = ({ modalShow, setModalShow }) => {

  const { formKey } = useForm();
  const [captchaReset, setCaptchaReset] = useState(false);

  const [captchaValid, isCaptchaValid] = useState(false);

  const [successMessage, setSuccessMessage] = useState(false);

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const validationSchema = Yup.object().shape({
    modal_full_name: Yup.string().required("Name is required"),
    modal_email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    emirates_id: Yup.string().required("Emirates Id is required"),
    modal_mobile_number: Yup.string()
      .matches(phoneRegExp, "*Invalid mobile number")
      .required("Mobile number required")
      .min(10, "*Digits in phone number should be equal to 10"),
  });

  const formik = useFormik({
    initialValues: {
      modal_full_name: "",
      modal_email: "",
      modal_mobile_number: "",
      emirates_id: "",
      form_nonce: formKey?.nonce,
      key: formKey?.key,
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (data) => {
      if (captchaValid) {
        formik.resetForm();
        enquirySubmit(data);
        setCaptchaReset(true);
      }
    },
  });
  const dispatch = useDispatch();

  const changeCaptcha = (value) => {
    if (value) {
      isCaptchaValid(true);
    }
  };

  const enquirySubmit = async (data) => {
    var payload = new URLSearchParams();
    payload.append("full_name", data.modal_full_name);
    payload.append("email_address", data.modal_email);
    payload.append("mobile_number", data.modal_mobile_number);
    payload.append("emirates-id", data.emirates_id);
    payload.append("form_nonce", data.form_nonce);
    payload.append("key", data.key);

    const resultAction = await dispatch(customerForm(payload));

    if (customerForm.fulfilled.match(resultAction)) {
      setSuccessMessage(resultAction?.payload?.data?.success);
      setTimeout(() => {
        setSuccessMessage(false);
        setModalShow(false);
      }, 2000);
    }
  };

  return (
    <>
      <div className={Style.fotmheader}>
        <div className={`h4 ${Style.formtitle}`}>Become a Customer</div>
        <p>
          This is exactly why we can’t wait for you to be a part of our family!.
        </p>
      </div>
      <div className={Style.formwrapper}>
        <form onSubmit={formik.handleSubmit}>
          <div className={Style.fieldwrapper}>
            <div
              class={`input-holder  ${
                formik.values.modal_full_name && `active`
              } ${
                formik.errors.modal_full_name && formik.touched.modal_full_name
                  ? `error`
                  : ""
              }`}
            >
              <input
                placeholder=" Full Name*"
                type="text"
                name="modal_full_name"
                id="modal_full_name"
                class="form-control"
                onChange={formik.handleChange}
                value={formik.values.modal_full_name}
                onBlur={formik.handleBlur}
              />

              <label for="modal_full_name" class="forvalue">
                Full Name*
              </label>
              {formik.touched.modal_full_name &&
              formik.errors.modal_full_name ? (
                <div className="text-danger">
                  {formik.errors.modal_full_name}
                </div>
              ) : null}
            </div>
            <div>
              <div
                class={`input-holder ${formik.values.modal_email && `active`} ${
                  formik.errors.modal_email && formik.touched.modal_email
                    ? `error`
                    : ""
                } `}
              >
                <input
                  placeholder="  Email Address*"
                  type="text"
                  name="modal_email"
                  id="email_id"
                  class="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.modal_email}
                  onBlur={formik.handleBlur}
                />
                <label for="modal_email" class="forvalue">
                  Email Address*
                </label>
                {formik.touched.modal_email && formik.errors.modal_email ? (
                  <div className="text-danger">{formik.errors.modal_email}</div>
                ) : null}
              </div>
            </div>
            <div>
              <div
                class={`input-holder  ${
                  formik.values.modal_mobile_number && `active`
                } ${
                  formik.errors.modal_mobile_number &&
                  formik.touched.modal_mobile_number
                    ? `error`
                    : ""
                }`}
              >
                <input
                  placeholder=" UAE Mobile Number*"
                  type="text"
                  name="modal_mobile_number"
                  id="modal_mobile_number"
                  class="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.modal_mobile_number}
                  onBlur={formik.handleBlur}
                  maxLength={10}
                />
                <label for="modal_mobile_number" class="forvalue">
                  UAE Mobile Number*
                </label>
                {formik.touched.modal_mobile_number &&
                formik.errors.modal_mobile_number ? (
                  <div className="text-danger">
                    {formik.errors.modal_mobile_number}
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div
                class={`input-holder  ${
                  formik.values.emirates_id && `active`
                } ${
                  formik.errors.emirates_id && formik.touched.emirates_id
                    ? `error`
                    : ""
                }`}
              >
                <input
                  placeholder=" Emirates ID*"
                  type="text"
                  name="emirates_id"
                  id="emirates_id"
                  class="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.emirates_id}
                  onBlur={formik.handleBlur}
                />
                <label for="emirates_id" class="forvalue">
                  Emirates ID*
                </label>
                {formik.touched.emirates_id && formik.errors.emirates_id ? (
                  <div className="text-danger">{formik.errors.emirates_id}</div>
                ) : null}
              </div>
            </div>
            <div>
              <div class="input-holder">
                <GoogleRecaptcha
                  onChange={changeCaptcha}
                  captchaReset={captchaReset}
                />
              </div>
            </div>
            <div>
              <div class="input-holder">
                <input
                  type="submit"
                  className={`btn btn-primary ${Style.submitbtn}`}
                  value="Submit"
                />
              </div>
            </div>
            <span>
              {successMessage ? "The Form was submitted successfully" : ""}
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default ModalForm;
