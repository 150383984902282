import React from "react";
import { Link } from "react-router-dom";
import IcoMoon from "../../Layouts/IcoMooon";
import Style from "./SingleBranch.module.scss";
import ReactHtmlParser from "react-html-parser";

const SingleBranch = ({ value }) => {


  return (
    <div className={Style.locitem} >
      <header className={Style.locname}>
        <span className={Style.icon}>
          <IcoMoon icon="location" size={14} />
        </span>
        {value.title}
      </header>
      <ul>
        <li className={Style.addr}>{ReactHtmlParser(value?.["branch-address"])}</li>
        <li className={Style.mob}>
          Mob: <Link to="/">{value?.["branch-mobile-number"]}</Link>
        </li>
        <li className={Style.date_time}>
          {ReactHtmlParser(value?.["branch-working-hours"])}
        </li>
      </ul>
      <a
        href={value?.["branch-direction-link"]}
        className="arw-link"
        target="_blank"
      >
        <span>
          <IcoMoon icon="arrow-right" size={16} />
        </span>
        Get Direction
      </a>
    </div>
  );
};
export default SingleBranch;
