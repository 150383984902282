import React, { useEffect, useState } from "react";
import Style from "./AboutStory.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import Assets from "../Layouts/Assets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useAboutUs } from "../../logic/useAboutUs";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";

gsap.registerPlugin(ScrollTrigger);

const AboutStory = () => {
  const { aboutBannerData } = useAboutUs();
  const { isActive } = useSelector((state) => state.pageAbout);

  useEffect(() => {
    gsap.fromTo(
      `.${Style.imgwrap} img`,
      {
        scale: 1.15,
        scrollTrigger: {
          trigger: `.${Style.imgwrap} img`,
          scrub: 1.2,
        },
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: `.${Style.imgwrap} img`,
          scrub: 1.2,
        },
      },
      0.2
    );
    gsap.to(`.${Style.ovalshape}`, {
      y: 400,
      duration: 1,
      delay: 0.4,
      filter: "blur(60px)",
      opacity: 0.6,
      scrollTrigger: {
        trigger: `.${Style.wrapper}`,
        scrub: 2,
      },
    });
  }, []);

  const [ref1, inView1] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref4, inView4] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div
      className={
        isActive ? `${Style.abtstorysec} ${Style.loaded}` : Style.abtstorysec
      }
    >
      <span className={Style.ovalshape}></span>
      <div className="container">
        <div className={Style.wrapper}>
          <div className="order-2 order-lg-1">
            <div className={Style.textwrap}>
              <h2 ref={ref1} className={`anim `}>
                {ReactHtmlParser(
                  aboutBannerData?.details["section-what-we-have-to-say"][
                  "some-words-from-vice-president"
                  ]
                )}
              </h2>
              <p ref={ref2} className={`anim`}>
                {ReactHtmlParser(
                  aboutBannerData?.details["section-what-we-have-to-say"][
                  "section-what-we-have-to-say-description"
                  ]
                )}
              </p>

              <div
                ref={ref3}
                className={` ${Style.storyteller}
                anim 
                 `}
              >
                <div className={Style.logowrap}>
                  {aboutBannerData && <img src={Assets.abt_logo_icon} alt="" />}
                </div>
                <div className={Style.authordetail}>
                  <div className={Style.name}>
                    {
                      aboutBannerData?.details["section-what-we-have-to-say"][
                      "vice-president-name"
                      ]
                    }
                  </div>
                  <div className={Style.desig}>
                    {ReactHtmlParser(
                      aboutBannerData?.details["section-what-we-have-to-say"][
                      "vice-president-more-details"
                      ]
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-1 order-lg-2">
            <div ref={ref4} className={`${Style.figwrap} anim`}>
              <figure className={Style.imgwrap}>
                {aboutBannerData && (
                  <img
                    src={
                      aboutBannerData?.details["section-what-we-have-to-say"][
                        "section-what-we-have-to-say-vice-president-image"
                      ]?.url
                    }
                    alt="vice president"
                  />
                )}
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutStory;
