import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenu } from "../../store/slices/menuSlice";

import ServiceCurrencyExchangePage from "../ServiceCurrencyExchange";
import ValueAddedServicesPage from "../ValueAddedServices";
import UsratiPage from "../Usrati";
import BuyBackGuaranteePage from "../BuyBackGuarantee";
import BulkCashDepartmentPage from "../BulkCashDepartment";
import RetailMoneyTransferPage from "../RetailMoneyTransfer";
import CorporateMoneyTransferPage from "../CorporateMoneyTransfer";
import ErrorPage from "../Error";

const OtherService = () => {
  
  const { commonSlug } = useParams();
  const dispatch = useDispatch();
  const menuData = useSelector((state) => state.menu);

  useEffect(() => {
    if (menuData.status === "idle") {
      menuHeader();
    }
  });

  const menuHeader = async () => {
    await dispatch(getMenu());
  };

  const checkComponent = (commonSlug) => {
    let servicList = menuData.menuData?.details
      ?.find((o) => o.sub_menu.length > 0)
      ?.sub_menu?.find((s) => s.url == "/services/" + commonSlug);

    switch (servicList?.template) {
      case "c-exchange":
        return <ServiceCurrencyExchangePage />;
      case "v-added-services":
        return <ValueAddedServicesPage />;
      case "ustrati":
        return <UsratiPage />;
      case "buy-back-guarantee":
        return <BuyBackGuaranteePage />;
      case "bcd":
        return <BulkCashDepartmentPage />;
      case "rt-money-transfer":
        return <RetailMoneyTransferPage />;
      case "c-money-transfer":
        return <CorporateMoneyTransferPage />;

      default:
        return <ErrorPage />;
    }
  };

  return checkComponent(commonSlug);
};

export default OtherService;
