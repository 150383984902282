import React from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceBankTransfer from "../ServiceBankTransfer";
import ServiceCashTransfer from "../ServiceCashTransfer";
import ServiceFaq from "../ServiceFaq";
import ServiceFeatures from "../ServiceFeatures";
import ServiceProcess from "../ServiceProcess";
import { useService } from "../../logic/useService";
import { useSelector } from "react-redux";
import { useDimensions } from "../../logic/Dimensions";

const RetailMoneyTransfer = () => {
  let loc=window.location.pathname.split("/")[2]

  const {width} = useDimensions();
  const result = useService(loc);

  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  return (
    <>
      <ServiceBanner />
      {width >= 768 && (<ServiceBannerFold />)}
      <ServiceBankTransfer
        data={
          serviceData?.details[0]?.page_sections?.[
            "page-details-section-one"
          ]?.["page-details-section-one-items"]?.[0]
        }
      />
      <ServiceCashTransfer
        data={
          serviceData?.details[0]?.page_sections?.[
            "page-details-section-one"
          ]?.["page-details-section-one-items"]?.[1]
        }
      />
      <ServiceProcess />
      <ServiceFeatures />
      <ServiceFaq />
    </>
  );
};

export default RetailMoneyTransfer;
