import { useEffect } from "react";
import { getService } from "../store/slices/serviceSlice";
import { useDispatch } from "react-redux";

export const useService = (params) => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPage();
  }, []);

  const fetchPage = async () => {
    await dispatch(getService({ slug: params }));
  };

  return fetchPage;
};
