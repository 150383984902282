import React, { useEffect } from "react";
import HomeBanner from "../HomeBanner";
import CurrencyFold from "../CurrencyFold";
import HomeServicesTab from "../HomeServicesTab";
import ExchangeRates from "../ExchangeRates";
import MoneyExchange from "../MoneyExchange";
import HomeBranchLocator from "../HomeBranchLocator";
import Testimonial from "../Testimonial";
import WhatsNew from "../WhatsNew";
import HomeAboutUs from "../HomeAboutUs";
import JoinUs from "../JoinUs";
import Assets from "../Layouts/Assets";

import Loader from "../Loader";

import { useHomeBanner } from "../../logic/useHomeBanner";
import { useHomeOurService } from "../../logic/useHomeOurService";
import { useSelector } from "react-redux";

const Home = () => {
  const { bannerData } = useHomeBanner();
  const { serviceData } = useHomeOurService();
  const { loaderClass } = useSelector((state) => state.service);

  return (
    <>
      {serviceData && bannerData ? (
        <>
          <HomeBanner bannerData={bannerData && bannerData} />
          <CurrencyFold />
          <HomeServicesTab
            serviceData={serviceData?.details?.["our-services-items"]}
            title={serviceData?.details["service-featured-main-title"]}
            loaderClass={loaderClass}
          />
          <ExchangeRates />
          <MoneyExchange />
          <HomeBranchLocator />
          <HomeAboutUs />
          <Testimonial />
          <WhatsNew />
          <JoinUs />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Home;
