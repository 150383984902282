import React from "react";
import Style from "../../../Footer.module.scss";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const ServicesDropMenu = () => {
  let location = useLocation();

  const { footerData } = useSelector((state) => state.footer);

  let footerData1 = footerData?.details?.menus?.footer_menu_services.map(
    (item, index) => {
      let data = { ...item, url: `/services${item.url}` };
      return data;
    }
  );

  return (
    <div className={Style.mobileview}>
      <Accordion
        defaultActiveKey={["0"]}
        alwaysOpen
        className={`${Style.menudropdown} ${Style.active}`}
      >
        <Accordion.Item eventKey="0" className={Style.menu_item}>
          <Accordion.Header className={Style.menu_head}>
            Services
          </Accordion.Header>
          <Accordion.Body className={Style.menu_list}>
            <ul>
              {footerData?.details?.menus.footer_menu_services &&
                footerData?.details?.menus?.footer_menu_services.length > 0 &&
                footerData1.map((value, key) => {
                  let activeMenu1 = location.pathname == value.url;
                  return (
                    <li key={key} className={activeMenu1 ? Style.active : ""}>
                      <Link key={value.id} to={value.url ? value.url : "/"}>
                        {value.title}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
export default ServicesDropMenu;
