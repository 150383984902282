import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: null,
  message: null,
  formKey: null,
};

export const getFormKey = createAsyncThunk(
  "form/getFormKey",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`form/security/get_nonce`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const enquiryForm = createAsyncThunk(
  "form/enquiryForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`form/action/enquire`,params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const applyNowForm = createAsyncThunk(
  "form/applyNowForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`form/action/apply-now`,params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resumeForm = createAsyncThunk(
  "form/resumeForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`form/action/drop-your-resume`,params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const customerForm = createAsyncThunk(
  "form/customerForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`form/action/add-new-customer`,params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {},
  extraReducers: {
    
    [enquiryForm.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [enquiryForm.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [enquiryForm.rejected]: (state, action) => {
      state.status = "failed";
    },


    [applyNowForm.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [applyNowForm.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [applyNowForm.rejected]: (state, action) => {
      state.status = "failed";
    },


    [resumeForm.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [resumeForm.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [resumeForm.rejected]: (state, action) => {
      state.status = "failed";
    },


    [customerForm.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [customerForm.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [customerForm.rejected]: (state, action) => {
      state.status = "failed";
    },
    

    
    [getFormKey.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFormKey.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.formKey = action?.payload?.data;
    },
    [getFormKey.rejected]: (state, action) => {
      state.status = "failed";
    },

    
  },
});

export default formSlice.reducer;
