import React, { useState, useRef, useEffect } from "react";
import Style from "./DesktopNav.module.scss";
import { Link } from "react-router-dom";
import LoginSignup from "../LoginSignup";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHeader } from "../../../../logic/useHeader";
import Search from "../Search";

const DesktopNav = ({
  setActiveSearch,
  closeSearch,
  openSearch,
  searchTerm,
  searchHandleChange,
  searchData,
  setSearchTerm,
}) => {
  const { menuData } = useHeader();

  const { isActive } = useSelector((state) => state.menu);

  const [dark, setDark] = useState(localStorage.getItem("lang"));
  const serviceRef = useRef(false);

  let location = useLocation();

  useEffect(() => {
    if (localStorage.i18nextLng == "en") {
      setDark("en");
    } else {
      setDark("ar");
    }
  }, [localStorage.i18nextLng]);

  const clickLang = (lang) => {
    if (lang === "en") {
      setDark("en");
    } else {
      setDark("ar");
    }
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  let navMenu = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About Us",
      path: "/about-us",
    },
    {
      name: "Services",
      path: "",
      sub_menu: [
        {
          name: "Currency Exchange",
          path: "/services/currency-exchange",
        },
        {
          name: "Retail Money Transfer",
          path: "/services/retail-money-transfer",
        },
        {
          name: "Corporate Money Transfer",
          path: "/services/corporate-money-transfer",
        },
        {
          name: "Value Added Services",
          path: "/services/value-added-services",
        },
        {
          name: "Usrati",
          path: "/services/usrati-program",
        },
        {
          name: "Buy Back Guarantee",
          path: "/services/buy-back-guarantee",
        },
        {
          name: " BCD",
          path: "/services/bulk-cash-department",
        },
      ],
    },
    {
      name: "Media",
      path: "/media",
    },
    {
      name: "Careers",
      path: "/careers",
    },
    {
      name: "Enquire",
      path: "/enquiry",
    },
    {
      name: "Branches",
      path: "/branch-locator",
    },
  ];

  const handleClickSubMenu = () => {
    serviceRef.current = true;
  };

  return (
    <>
      <nav className={Style.mainav}>
        {isActive ? (
          <ul>
            {menuData?.details?.length > 0 &&
              menuData?.details?.map((item, navIndex) => {
                let activeMenu = location.pathname == item.url;
                return (
                  <>
                    {item.title == "Services" ? (
                      <li
                        className={
                          location.pathname.includes("services")
                            ? Style.active
                            : ""
                        }
                      >
                        <Link to={`${item.url}`}>{item?.title}</Link>
                        <ul>
                          {item.sub_menu.map((menuItem, menuIndex) => {
                            let activeSubMenu =
                              location.pathname == menuItem.url;
                            return (
                              <li
                                className={activeSubMenu ? Style.active : ""}
                                onClick={() => handleClickSubMenu()}
                              >
                                <Link to={`${menuItem.url}`}>
                                  {menuItem?.title}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    ) : (
                      <li className={activeMenu ? Style.active : ""}>
                        <Link to={`${item.url}`}>{item.title}</Link>
                      </li>
                    )}
                  </>
                );
              })}

            <li className={`only-desktop ${Style.lang}`}>
              <Link
                to="#"
                onClick={() => {
                  clickLang("en");
                }}
                className={dark === "en" ? Style.activeLang : ""}
              >
                English
              </Link>
              <span className={Style.vbar}></span>
              <Link
                to="#"
                onClick={() => {
                  clickLang("ar");
                }}
                className={dark === "ar" ? Style.activeLang : ""}
              >
                عربى
              </Link>
            </li>
          </ul>
        ) : (
          <ul className={Style.static_menu}>
            {navMenu.map((item, navIndex) => {
              let activeMenu = location.pathname == item.path;
              return (
                <>
                  {item.name == "Services" ? (
                    <li
                      className={
                        location.pathname.includes("services")
                          ? Style.active
                          : ""
                      }
                    >
                      <Link to={`${item.path}`}>{item.name}</Link>
                      <ul>
                        {item.sub_menu.map((menuItem, menuIndex) => {
                          let activeSubMenu =
                            location.pathname == menuItem.path;
                          return (
                            <li
                              className={activeSubMenu ? Style.active : ""}
                              onClick={() => handleClickSubMenu()}
                            >
                              <Link to={`${menuItem.path}`}>
                                {menuItem.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  ) : (
                    <li className={activeMenu ? Style.active : ""}>
                      <Link to={`${item.path}`}>{item.name}</Link>
                    </li>
                  )}
                </>
              );
            })}

            <li className={`only-desktop ${Style.lang}`}>
              <Link
                to="#"
                onClick={() => {
                  clickLang("en");
                }}
                className={dark === "en" ? Style.activeLang : ""}
              >
                English
              </Link>
              <span className={Style.vbar}></span>
              <Link
                to="#"
                onClick={() => {
                  clickLang("ar");
                }}
                className={dark === "ar" ? Style.activeLang : ""}
              >
                عربى
              </Link>
            </li>
          </ul>
        )}
        <Search
          closeSearch={closeSearch}
          openSearch={openSearch}
          searchTerm={searchTerm}
          searchHandleChange={searchHandleChange}
          searchData={searchData}
          setSearchTerm={setSearchTerm}
          setActiveSearch={setActiveSearch}
        />
      </nav>
      <LoginSignup /> {/* Hide this After max-width: 1199.98px */}
    </>
  );
};

export default DesktopNav;
