import { useDynamicMenu } from "../../logic/useDynamicMenu";
import Style from "./DynamicMenu.module.scss";
import ReactHtmlParser from "react-html-parser";

const DynamicMenu = () => {
  const { dynamicData } = useDynamicMenu();

  return (
    <>
      <div className={`container ${Style.loaded}`}></div>

      <div className={Style.postdetail}>
        <div className="container">
          <div className={`${Style.smcontainer}`}>
            <div className={Style.smwrapper}>
              <div className={Style.title_detail}>
                <div className={`smtitle ${Style.smtitle}`}>
                  {/* {dynamicData?.post_title} */}
                </div>
                <h2>{dynamicData?.post_title}</h2>
                <div className={Style.postdate}>
                  {/* <p>{ReactHtmlParser(dynamicData?.post_content)}</p> */}
                </div>
              </div>
            </div>
            <div className={Style.postfigwrap}>
              <figure className={Style.imgwrap}>
                <img src={dynamicData?.post_image} alt="" />
              </figure>
            </div>
            <div className={Style.smwrapper}>
              <div className={Style.postcnt}>
                <p>{ReactHtmlParser(dynamicData?.post_content)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicMenu;
