import React, { useState } from "react";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { useSelector } from "react-redux";

const SocialMedias = () => {
  const { footerData } = useSelector((state) => state.footer);

  return (
    <>
      {footerData && (
        <ul>
          <li>
            <Link to="#">
              <FacebookShareButton
                url={footerData?.details?.menus?.social_media[0]?.url}
              >
                <IcoMoon icon="facebook" size={18} />
              </FacebookShareButton>
            </Link>
          </li>
          <li>
            <Link to="#">
              <TwitterShareButton
                url={footerData?.details?.menus?.social_media[1]?.url}
              >
                <IcoMoon icon="twitter" size={19.65} />
              </TwitterShareButton>
            </Link>
          </li>
          <li>
          <Link to="#">
              <LinkedinShareButton
                url={footerData?.details?.menus?.social_media[2]?.url}
              >
                <IcoMoon icon="linkedin" size={19.65} />
              </LinkedinShareButton>
            </Link>
          </li>

          <li>
            <a
              href={`https://www.youtube.com/channel/UC3RrKmP9q5wmgVOs5OCMPXA`}
              target="_blank"
            >
              <IcoMoon icon="youtube" size={23.95} />
            </a>
          </li>
        </ul>
      )}
    </>
  );
};

export default SocialMedias;
