import React from "react";
import Style from "./AboutPartnersAndAssociates.module.scss";
import SwiperCore, { Navigation, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useAboutUs } from "../../logic/useAboutUs";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

const AboutPartnersAndAssociates = () => {
  const { aboutPartnersData } = useAboutUs();

  const { isActive } = useSelector((state) => state.pageAbout);

  SwiperCore.use([Navigation, Autoplay, FreeMode]);
  return (
    <div
      className={
        isActive ? `${Style.partnersec} ${Style.loaded}` : Style.partnersec
      }
    >
      <div className="container">
        <div className={`sectitle center`}>
          <h2>
            {ReactHtmlParser(
              aboutPartnersData?.details?.["section-partners-associates"]?.[
                "section-partners-associates-main-title"
              ]
            )}
          </h2>
        </div>

        {aboutPartnersData?.details && (
          <Swiper
            className={Style.slider}
            loop={true}
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2000}
            grabCursor={true}
            mousewheelControl={true}
            keyboardControl={true}
            breakpoints={{
              0: {
                slidesPerView: 2.66,
                spaceBetween: 30,
              },
              480: {
                slidesPerView: 2.7,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
              992: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 80,
              },
              1600: {
                slidesPerView: 5,
                spaceBetween: 112,
              },
            }}
          >
            {aboutPartnersData?.details?.["section-partners-associates"]?.[
              "section-partners-associates-items"
            ] &&
              aboutPartnersData?.details?.["section-partners-associates"]?.[
                "section-partners-associates-items"
              ].length > 0 &&
              aboutPartnersData?.details?.["section-partners-associates"]?.[
                "section-partners-associates-items"
              ].map((value, key) => {
                return (
                  <SwiperSlide key={key}>
                    <figure className={Style.items}>
                      <div className={Style.imgwrap}>
                        <img src={value?.["partner-logo"]?.url} />
                      </div>
                    </figure>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default AboutPartnersAndAssociates;
