import React from "react";
import Style from "./CareersDetailShare.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
} from "react-share";
import { useCareersJobsSingle } from "../../logic/useCareersJobsSingle";
import { useSelector } from "react-redux";

const CareersDetailShare = () => {
  const { careersJobSingleData } = useCareersJobsSingle();
  const { isActive } = useSelector((state) => state.careersJobSingle);

  return (
    <div
      className={
        isActive
          ? `${Style.sharedetailsec} ${Style.loaded}`
          : Style.sharedetailsec
      }
    >
      <div className="container">
        <div className={`smcontainer`}>
          <div className={Style.wrapper}>
            <div className={`h5 ${Style.titlesec}`}>
              Have A Friend Who Would Love This?
            </div>
            <div>
              <p>Share this with your network</p>
            </div>
            <div className={Style.smedias}>
              <ul>
                <li>
                  <Link to="#" target="_blank">
                    <FacebookShareButton
                      url={`${window.location.host}/careers-detail/${careersJobSingleData?.details[0]?.slug}/${careersJobSingleData?.details[0]?.ID}`}
                    >
                      <IcoMoon icon="facebook" size={16} />
                    </FacebookShareButton>
                  </Link>
                </li>
                <li>
                  <Link to="#" target="_blank">
                    <TwitterShareButton
                      url={`${window.location.host}/careers-detail/${careersJobSingleData?.details[0]?.slug}/${careersJobSingleData?.details[0]?.ID}`}
                    >
                      <IcoMoon icon="twitter" size={15} />
                    </TwitterShareButton>
                  </Link>
                </li>
                <li>
                  <a href={`https://www.instagram.com/`} target="_blank">
                    <IcoMoon icon="insta" size={17} />
                  </a>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.host}/careers-detail/${careersJobSingleData?.details[0]?.slug}/${careersJobSingleData?.details[0]?.ID}`
                      );
                    }}
                  >
                    <IcoMoon icon="attach" size={14} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersDetailShare;
