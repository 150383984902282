import React, { useState, useEffect } from "react";
import Style from "./Faq.module.scss";
import { Breadcrumb, Accordion } from "react-bootstrap";
import FaqTabs from "./FaqTabs";
import FaqResult from "./FaqResult";
import { useFaq } from "../../logic/useFaq";
import * as _ from "lodash";

const Faq = () => {
  const [filteredIndex, setFilteredIndex] = useState(0);

  const { faqData } = useFaq();

  useEffect(() => {
    if (faqData?.details.length > 0) {
      const firstData = _.first(faqData?.details);
      setFilteredIndex(firstData);
    }
  }, [faqData]);

  return (
    <div className={Style.faqpage}>
      <div className="container">
        <div className={`${Style.smcontainer}`}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item>Frequently Asked Questions</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={Style.titlehead}>
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className={Style.wrapper}>
          <div className={Style.tabcol}>
            <FaqTabs
              faqData={faqData}
              filteredIndex={filteredIndex}
              setFilteredIndex={setFilteredIndex}
            />
          </div>
          <div className={Style.resultcol}>
            <FaqResult faqData={faqData} filteredIndex={filteredIndex} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
