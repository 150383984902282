import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNearestBranch } from "../store/slices/branchesSlice";

export const useNearestBranch = () => {
  const dispatch = useDispatch();
  // const nearestBranchData = useSelector((state) => state.nearestBranch);

  const postLocation = async (params) => {
    await dispatch(getNearestBranch(params));
  };

  return {
    // nearestBranchData: nearestBranchData?.nearestBranchData,
    postLocation,
  };
};
