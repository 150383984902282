import React from "react";
import Style from "../CurrencyFold.module.scss";
import Icomoon from "../../Layouts/IcoMooon";

const Shimmer = () => {
  return (
    <div className={Style.form_shimmer}>
      <div className={Style.formsec}>
        <div className={Style.currency_from}>
          <div className={Style.cols}>
            <div className={`input-holder active ${Style.input_holder}`}>
              <label htmlFor="currency_from">Currency From</label>
              <input
                type="text"
                className={`form-control ${Style.form_control}`}
                id="currency_from"
                pattern="([0-9]|[0-9]|[0-9])"
                maxlength="6"
                value="1000"
              />
            </div>
          </div>
          <div className={Style.cols}>
            <div className={`select-holder ${Style.select_holder}`}>
              <label>UAE Dirham</label>

              <div className="form-select disabled form_select dropdown">
                <button
                  type="button"
                  aria-expanded="false"
                  className="dropdown-toggle btn btn-primary"
                >
                  <div className={Style.flag}>
                    <img src="http://sharafexchange.webc.in/wp-content/uploads/2022/05/255px-Flag_of_the_United_Arab_Emirates.svg.png" />
                  </div>
                  <span>AED</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={Style.currency_btnsec}>
          <button type="button" className={Style.convertbtn}>
            <span className={Style.icons}>
              <Icomoon icon="multi-arrow" size={22} />
            </span>
          </button>
        </div>

        <div className={Style.currency_to}>
          <div className={Style.cols}>
            <div className={`input-holder active ${Style.input_holder}`}>
              <label htmlfor="currency_from">Convert To</label>
              <input
                className={`form-control ${Style.form_control}`}
                id="currency_from"
                value={21089.47}
                disabled
              />
            </div>
          </div>

          <div className={Style.cols}>
            <div className={`select-holder ${Style.select_holder}`}>
              <label>Indian Rupee</label>
              <div className={`form-select ${Style.form_select}`}>
                <button
                  type="button"
                  id="areaof-interest-dropdown"
                  aria-expanded="false"
                  className="dropdown-toggle btn btn-primary"
                >
                  <div className={Style.flag}>
                    <img
                      alt=""
                      src="http://sharafexchange.webc.in/wp-content/uploads/2022/04/india-flag.png"
                    />
                  </div>
                  <span>INR</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={Style.btm}>
          <div className={Style.refresh}>
            <p>The rates will be updated every day</p>
          </div>
          <div className={Style.rate_btn}>
            <a href="/services/currency-exchange">Today’s Exchange Rates</a>
          </div>
        </div>
      </div>
      <div className={Style.graphsec}>
        <div className={Style.currency_status}>
          <div className={Style.currency_status_to}>
            <small>1 AED equals</small>
            <span>21.09</span>
            <span> INR</span>
          </div>
          <div className={Style.currency_status_details}>
            <div className={`h6 ${Style.currency_status_time}`}>
              27 Jun, 10:11 am UTC · Disclaimer
            </div>
            <div className={Style.currency_status_disclaimer}>
              <span></span>
              <div className={Style.currency_status_disclaimer_text}>
                Rates are indicative and subject to change.
              </div>
            </div>
          </div>
        </div>
        <div className={Style.graphwrap}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Shimmer;
