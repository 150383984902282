import React, { useEffect, useRef, useState } from "react";
import Style from "./CurrencyFold.module.scss";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import IcoMoon from "../Layouts/IcoMooon";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import { useCurrencyRates } from "../../logic/useCurrencyRates";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import { useCurrencyGraph } from "../../logic/useCurrencyGraph";
import Shimmer from "./Shimmer";

gsap.registerPlugin(ScrollTrigger);

const CurrencyFold = () => {
  const { currencyRatesData, ratesOfCurrency } = useCurrencyRates();
  const [currencyRate, setCurrencyRate] = useState(null);
  const [currencyFrom, setCurrencyFrom] = useState(1000);
  const [result, setResult] = useState(null);
  const [swapClass, setSwapClass] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(null);

  const rateBtn = window.location.pathname == "/";
  const { t } = useTranslation(["common"]);
  const {
    graphData,
    graphOptions,
    graphDays,
    exchangeDay,
    setSelectedCurrency,
    active,
    timeGrpah,
  } = useCurrencyGraph();

  useEffect(() => {
    gsap.to(foldtrigger.current, {
      x: 110,
      y: -150,
      duration: 1,
      delay: 0.4,
      filter: "blur(60px)",
      scrollTrigger: {
        trigger: foldtrigger.current,
        scrub: 1.5,
      },
    });
    gsap.to(foldbottom.current, {
      y: -70,
      duration: 0.5,
      delay: 0.4,
      width: "100%",
      height: 300,
      scrollTrigger: {
        trigger: foldbottom.current,
        scrub: 1.5,
      },
    });
  }, []);

  const foldtrigger = useRef();
  const foldbottom = useRef();

  useEffect(() => {
    const getFirstdata = _.first(currencyRatesData?.details);
    const object = {
      country_flag: getFirstdata?.country_flag,
      currency_code: getFirstdata?.currency_code,
      exchange_rate: getFirstdata?.exchange_rate,
      title: getFirstdata?.title,
    };
    let converteData = currencyFrom * object?.exchange_rate;
    setResult(converteData?.toFixed(2));
    setCurrencyRate(object);

    const data = _.find(currencyRatesData?.details, {
      currency_code: "AED",
    });
    setCurrentCurrency(data);
  }, [currencyRatesData?.details]);

  const toggleClass = () => {
    setSwapClass(!swapClass);
  };

  useEffect(() => {
    if (swapClass) {
      let currencyData = currencyFrom / currencyRate?.exchange_rate;
      setResult(currencyData?.toFixed(2));
    } else {
      let currencyData = currencyFrom * currencyRate?.exchange_rate;
      setResult(currencyData?.toFixed(2));
    }
  }, [swapClass]);

  const dropDownValues = (value) => {
    const data = _.find(currencyRatesData?.details, {
      title: value,
    });

    setSelectedCurrency(data?.currency_code);
    const object = {
      country_flag: data?.country_flag,
      currency_code: data?.currency_code,
      exchange_rate: data?.exchange_rate,
      title: data?.title,
    };
    setCurrencyRate(object);

    if (swapClass) {
      let currencyData = currencyFrom / data?.exchange_rate;
      setResult(currencyData?.toFixed(2));
    } else {
      let currencyData = currencyFrom * data?.exchange_rate;
      setResult(currencyData?.toFixed(2));
    }
  };

  const handlechangeCurrencyFrom = (e) => {
    setCurrencyFrom(e.target.value);
    if (swapClass) {
      let currencyData = e.target.value / currencyRate?.exchange_rate;
      setResult(currencyData?.toFixed(2));
    } else {
      let currencyData = e.target.value * currencyRate?.exchange_rate;
      setResult(currencyData?.toFixed(2));
    }
  };

  return (
    <div className={`currencyfold ${Style.currencyfold} ${Style.loaded}`}>
      <div className="container">
        <form>
          <div
            className={
              swapClass
                ? `${Style.currencyform} ${Style.swapped} `
                : Style.currencyform
            }
          >
            <span className={Style.foldbefore} ref={foldtrigger}></span>
            <span className={Style.bottomshape} ref={foldbottom}></span>
            <span className={Style.foldroundrec}></span>
            {currencyRatesData ? (
              <>
                <div className={Style.formsec}>
                  <div className={Style.currency_from}>
                    <div className={Style.cols}>
                      <div
                        className={`input-holder active ${Style.input_holder}`}
                      >
                        <label htmlFor="currency_from">{t("headingOne")}</label>
                        <input
                          type="text"
                          className={`form-control ${Style.form_control}`}
                          id="currency_from"
                          value={currencyFrom}
                          onChange={handlechangeCurrencyFrom}
                          pattern="([0-9]|[0-9]|[0-9])"
                          maxLength="6"
                        />
                      </div>
                    </div>

                    {swapClass ? (
                      <div className={Style.cols}>
                        <div className={`select-holder ${Style.select_holder}`}>
                          <label>{currencyRate?.title}</label>

                          <DropdownButton
                            id="areaof-interest-dropdown"
                            className={`form-select ${Style.form_select}`}
                            title={
                              <>
                                <div className={Style.flag}>
                                  <img
                                    src={currencyRate?.country_flag}
                                    alt=""
                                  />
                                </div>
                                <span>{currencyRate?.currency_code}</span>
                              </>
                            }
                            onSelect={(e) => {
                              dropDownValues(e);
                            }}
                          >
                            <ul>
                              <li>
                                {currencyRatesData?.details &&
                                  currencyRatesData?.details.length > 0 &&
                                  currencyRatesData?.details.map(
                                    (value, key) => {
                                      return (
                                        <Dropdown.Item eventKey={value.title}>
                                          <div className={Style.flag}>
                                            <img
                                              src={value?.country_flag}
                                              alt=""
                                            />
                                          </div>
                                          <span>{value?.currency_code}</span>
                                        </Dropdown.Item>
                                      );
                                    }
                                  )}
                              </li>
                            </ul>
                            <div className={Style.down}>
                              <IcoMoon icon="down-arrow" size={12} />
                            </div>
                          </DropdownButton>
                        </div>
                      </div>
                    ) : (
                      <div className={Style.cols}>
                        <div className={`select-holder ${Style.select_holder}`}>
                          <label>{currentCurrency?.title}</label>

                          <DropdownButton
                            className={`form-select disabled ${Style.form_select}`}
                            title={
                              <>
                                <div className={Style.flag}>
                                  <img src={currentCurrency?.country_flag} />
                                </div>
                                <span>{currentCurrency?.currency_code}</span>
                              </>
                            }
                          ></DropdownButton>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={Style.currency_btnsec}>
                    <button type="button" className={Style.convertbtn}>
                      <span className={Style.icons} onClick={toggleClass}>
                        <IcoMoon icon="multi-arrow" size={22} />
                      </span>
                    </button>
                  </div>

                  <div className={Style.currency_to}>
                    <div className={Style.cols}>
                      <div
                        className={`input-holder active ${Style.input_holder}`}
                      >
                        <label htmlfor="currency_from">{t("headingTwo")}</label>
                        <input
                          className={`form-control ${Style.form_control}`}
                          id="currency_from"
                          value={result && result}
                          disabled
                        />
                      </div>
                    </div>

                    {swapClass ? (
                      <div className={Style.cols}>
                        <div className={`select-holder ${Style.select_holder}`}>
                          <label>{currentCurrency?.title}</label>

                          <DropdownButton
                            className={`form-select disabled ${Style.form_select}`}
                            title={
                              <>
                                <div className={Style.flag}>
                                  <img src={currentCurrency?.country_flag} />
                                </div>
                                <span>{currentCurrency?.currency_code}</span>
                              </>
                            }
                          ></DropdownButton>
                        </div>
                      </div>
                    ) : (
                      <div className={Style.cols}>
                        <div className={`select-holder ${Style.select_holder}`}>
                          <label>{currencyRate?.title}</label>

                          <DropdownButton
                            id="areaof-interest-dropdown"
                            className={`form-select ${Style.form_select}`}
                            title={
                              <>
                                <div className={Style.flag}>
                                  <img
                                    src={currencyRate?.country_flag}
                                    alt=""
                                  />
                                </div>
                                <span>{currencyRate?.currency_code}</span>
                              </>
                            }
                            onSelect={(e) => {
                              dropDownValues(e);
                            }}
                          >
                            <ul>
                              <li>
                                {currencyRatesData?.details &&
                                  currencyRatesData?.details.length > 0 &&
                                  currencyRatesData?.details.map(
                                    (value, key) => {
                                      return (
                                        <Dropdown.Item eventKey={value.title}>
                                          <div className={Style.flag}>
                                            <img
                                              src={value?.country_flag}
                                              alt=""
                                            />
                                          </div>
                                          <span>{value?.currency_code}</span>
                                        </Dropdown.Item>
                                      );
                                    }
                                  )}
                              </li>
                            </ul>
                            <div className={Style.down}>
                              <IcoMoon icon="down-arrow" size={12} />
                            </div>
                          </DropdownButton>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={Style.btm}>
                    <div className={Style.refresh}>
                      {/* <span className={Style.icons}>
                    <IcoMoon icon="refresh" size={15} />
                  </span> */}
                      <p>
                        {t("rates")}
                        {/* Rates will be refreshed in <span>30 seconds</span> */}
                      </p>
                    </div>
                    <div className={Style.rate_btn}>
                      {rateBtn ? (
                        <Link to={"services/currency-exchange"}>
                          {t("heading")}
                        </Link>
                      ) : null}
                      {/* <input
                    type="submit"
                    className={`btn btn-primary ${Style.btn}`}
                    value={t("buttonText")}
                  /> */}
                    </div>
                  </div>
                </div>
                <div className={Style.graphsec}>
                  <div className={Style.currency_status}>
                    <div className={Style.currency_status_to}>
                      <small className="h6">1 AED equals</small>
                      <span>
                        {currencyRate?.exchange_rate &&
                          parseFloat(currencyRate?.exchange_rate).toFixed(2)}
                      </span>
                      <span> {currencyRate?.currency_code}</span>
                    </div>
                    <div className={Style.currency_status_details}>
                      <div className={`h6 ${Style.currency_status_time}`}>
                        {timeGrpah?.last_update} · Disclaimer
                      </div>
                      <div className={Style.currency_status_disclaimer}>
                        <span></span>
                        <div className={Style.currency_status_disclaimer_text}>
                          Rates are indicative and subject to change.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Style.graphwrap}>
                    <div>
                      <div className={Style.graph_dates}>
                        {graphDays.map((value, i) => {
                          return (
                            <div
                              onClick={() => {
                                exchangeDay(value);
                              }}
                              className={
                                active == value
                                  ? `${Style.item} ${Style.item_active}`
                                  : Style.item
                              }
                            >
                              {value}
                            </div>
                          );
                        })}
                      </div>
                      {graphData.length !== 0 && (
                        <Chart
                          className={Style.graph_dates_chart}
                          chartType="LineChart"
                          width={"100%"}
                          height={"auto"}
                          data={graphData}
                          options={graphOptions}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Shimmer />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default CurrencyFold;
