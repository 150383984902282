import React from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceContentSection from "../ServiceContentSection";
import ServiceFaq from "../ServiceFaq";
import ServiceFeatures from "../ServiceFeatures";
import { useService } from "../../logic/useService";
import { useDimensions } from "../../logic/Dimensions";

const BulkCashDepartment = () => {

  let loc=window.location.pathname.split("/")[2]

  const result = useService(loc);

  const { width } = useDimensions();

  return (
    <>
      <ServiceBanner />
      {width >= 768 && <ServiceBannerFold />}
      <ServiceContentSection />
      <ServiceFeatures />
      <ServiceFaq />
    </>
  );
};

export default BulkCashDepartment;
