import React, { useEffect, useState } from "react";
import Style from "../Footer.module.scss";
import { useSelector } from "react-redux";
import { useFormik, Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDimensions } from "../../../../logic/Dimensions";

const FooterContact = () => {
  const MC_API = process.env.REACT_APP_MAILCHIMB_API_KEY;
  const MCIP_ID = process.env.REACT_APP_MAILCHIMB_ID;

  const { width } = useDimensions();

  const { footerData } = useSelector((state) => state.footer);

  const [emailSuccess, setEmailSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema,

    enableReinitialize: true,

    onSubmit: (value) => {
      formik.resetForm();

      axios({
        method: "get",
        dataType: "JSON",
        url: `https://us2.api.mailchimp.com/2.0/lists/subscribe.json?apikey=${MC_API}&id=${MCIP_ID}&email[email]=${value.email}`,

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          formik.resetForm();
          setEmailSuccess(true);
          setTimeout(() => {
            setEmailSuccess(false);
          }, 2500);
        })
        .catch((response) => {
          formik.resetForm();
          setEmailSuccess(true);
          setTimeout(() => {
            setEmailSuccess(false);
          }, 2500);
        });
    },
  });

  return (
    <div className={Style.ftrcontacts}>
      {width >= 991 && (
        <div className={`${Style.cols} ${Style.phcol}`}>
          {footerData && <div className={Style.titlesec}>Toll Free</div>}
          <div className={Style.detail}>
            <p>
              <a
                href={`tel:${footerData?.details?.footer_more_details?.wac_footer_toll_free_number}`}
              >
                {
                  footerData?.details?.footer_more_details
                    ?.wac_footer_toll_free_number
                }
              </a>
            </p>
          </div>
        </div>
      )}
      {width >= 991 && (
        <div className={`${Style.cols} ${Style.mailcol}`}>
          {footerData && <div className={Style.titlesec}>Email</div>}
          <div className={Style.detail}>
            <p>
              <a
                href={`mailto:${footerData?.details?.footer_more_details?.wac_footer_email}`}
              >
                {footerData?.details?.footer_more_details?.wac_footer_email}
              </a>
            </p>
          </div>
        </div>
      )}

      {footerData && (
        <div className={`${Style.cols} ${Style.newsletcol}`}>
          {width <= 968 ? (
            <div className={Style.titlesec}>Subscribe to Our Newsletter</div>
          ) : (
            <div className={Style.titlesec}>Newsletter</div>
          )}

          <form onSubmit={formik.handleSubmit}>
            <div className={`${Style.input_holder} ${Style.error}`}>
              <input
                id="email"
                name="email"
                type="email"
                className={Style.nwsltr_control}
                placeholder="Your Email Address"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={Style.text_danger}>{formik.errors.email}</div>
              ) : null}

              {emailSuccess && (
                <div className={Style.text_success}>
                  Please check your mail and confirm your subscription
                </div>
              )}
              <button
                type="submit"
                className={`btn btn-primary ${Style.nwsltr_btn}`}
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default FooterContact;
