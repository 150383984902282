import { useEffect, useState } from "react";
import { getBranches } from "../store/slices/branchesSlice";
import { useDispatch, useSelector } from "react-redux";

export const useBranches = (page) => {
  const dispatch = useDispatch();
  const branchesData = useSelector((state) => state.branches);

  useEffect(() => {
    fetchBranchDetails(page);
  }, []);

  const fetchBranchDetails = async (param) => {
    const resultAction = await dispatch(getBranches(param));
  };
  return {
    branchesData: branchesData?.branchesData,
    fetchBranchDetails,
  };
};
