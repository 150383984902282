import React from "react";
import Style from "../../../Footer.module.scss";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const CompanyDropMenu = () => {
  const { footerData } = useSelector((state) => state.footer);

  let location = useLocation();

  return (
    <div className={Style.mobileview}>
      <Accordion
        defaultActiveKey={["0"]}
        alwaysOpen
        className={Style.menudropdown}
      >
        <Accordion.Item eventKey="0" className={Style.menu_item}>
          <Accordion.Header className={Style.menu_head}>
            Company
          </Accordion.Header>
          <Accordion.Body className={Style.menu_list}>
            <ul>
              {footerData?.details?.menus.footer_menu_company_part1 &&
                footerData?.details?.menus?.footer_menu_company_part1.length >
                  0 &&
                footerData?.details?.menus?.footer_menu_company_part1.map(
                  (value, key) => {
                    let activeMenu = location.pathname == value?.url;
                    return (
                      <li key={key} className={activeMenu ? Style.active : ""}>
                        <Link
                          key={value?.id}
                          to={value?.url ? value?.url : "/"}
                        >
                          {value?.title}
                        </Link>
                      </li>
                    );
                  }
                )}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
export default CompanyDropMenu;
