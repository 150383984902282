import React from "react";
import Style from "./ExchangeSendMoney.module.scss";
import { Button } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import IcoMoon from "../Layouts/IcoMooon";
import { useSelector } from "react-redux";

const ExchangeSendMoney = () => {
  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  let data =
    serviceData?.details[0] &&
    serviceData?.details[0]?.page_sections?.["page-details-section-one"] &&
    serviceData?.details[0]?.page_sections?.["page-details-section-one"]?.[
      "page-details-section-one-items"
    ][0];

  return (
    <div
      className={
        loaderClass
          ? `${Style.exchangesendmoney} ${Style.loaded}`
          : Style.exchangesendmoney
      }
    >
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.textcol}>
            <div className={Style.textwrap}>
              <h2>{data?.["page-details-section-one-title"]}</h2>
              <p>{data?.["page-details-section-one-description"]}</p>

              {/* <Button className={`btn btn-primary ${Style.btn}`}>
                {data?.["page-details-section-one-button-text"]}{" "}
              </Button> */}
            </div>
          </div>
          <div className={Style.imgcol}>
            <div className={Style.devicewrap}>
              <figure className={Style.imgwrap}>
                <div className={Style.imgsec}>
                  <img src={Assets.appview_01} />
                </div>
              </figure>
              <div className={`${Style.moneychart} bounceanim`}>
                <div className={Style.chartwrap}>
                  <div className={Style.money}>
                    <span className={Style.flag}>
                      <IcoMoon icon={"AE"} />
                    </span>
                    <div className={Style.currency}>
                      <span className={Style.main}>1 AED</span>
                      <span className={Style.convert}>0.27 USD</span>
                    </div>
                  </div>
                  <div className={Style.figwrap}>
                    <figure>
                      <img src={Assets.dummy_graph} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeSendMoney;
