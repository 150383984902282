import { useEffect } from "react";
import { getCurrent } from "../store/slices/homeCurrentOpeningSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHomeCurrentOpening = () => {
  const dispatch = useDispatch();
  const currentData = useSelector((state) => state.current);

  useEffect(() => {
      current();
    
  }, []);

  const current = async () => {
    await dispatch(getCurrent());
  };

  return {
    currentData: currentData?.currentData,
  };
};
