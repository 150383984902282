import React, { useRef } from "react";
import CareersDesignationView from "../CareersDesignationView";
import CareersDetailApplyNow from "../CareersDetailApplyNow";
import CareersDetailRelatedJobs from "../CareersDetailRelatedJobs";
import CareersDetailRequirements from "../CareersDetailRequirements";
import CareersDetailShare from "../CareersDetailShare";
import CareersDetailWhatYoullBeDoing from "../CareersDetailWhatYoullBeDoing";

const CareersDetail = () => {
  const applyNowRef = useRef(null);

  return (
    <div>
      <CareersDesignationView applyNowRef={applyNowRef} />
      <CareersDetailWhatYoullBeDoing />
      <CareersDetailRequirements />
      <CareersDetailApplyNow applyNowRef={applyNowRef} />
      <CareersDetailShare />
      <CareersDetailRelatedJobs />
    </div>
  );
};

export default CareersDetail;
