import { useEffect, useState } from "react";
import { getSingleBlog } from "../store/slices/singleBlogSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useSingleBlog = () => {
  const { categories_ids, ID } = useParams();

  const dispatch = useDispatch();
  const singleBlogData = useSelector((state) => state.singleBlog);

  useEffect(() => {
    singleBlog();
  }, [categories_ids, ID]);

  const singleBlog = async () => {
    await dispatch(getSingleBlog({ pid: ID, cat_id: categories_ids }));
  };

  return {
    singleBlogData: singleBlogData?.singleBlogData,
  };
};
