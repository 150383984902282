import React, { useEffect, useRef } from "react";
import Style from "./ServiceContentSection.module.scss";
import ServiceContent from "./ServiceContent";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ServiceContentSection = () => {
  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  useEffect(() => {
    gsap.fromTo(
      `.${Style.imgwrap} img`,
      {
        scale: 1.2,
        scrollTrigger: {
          trigger: `.${Style.imgwrap} img`,
          scrub: 1.2,
        },
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: `.${Style.imgwrap} img`,
          scrub: 1.2,
        },
      },
      0.2
    );
  });

  let data =
    serviceData?.details[0] &&
    serviceData?.details[0]?.page_sections?.["page-details-section-one"]?.[
      "page-details-section-one-items"
    ]?.[0];

  return (
    <>
      <div
        className={
          loaderClass
            ? `${Style.servicecntsec} ${Style.loaded}`
            : Style.servicecntsec
        }
      >
        <div className="container">
          <div className={Style.wrapper}>
            <div className={Style.textcol}>
              <ServiceContent
                serviceData={data}
                loaderClass={loaderClass}
                btnData={serviceData?.details[0]?.page_sections}
              />
            </div>
            <div className={Style.imgcol}>
              <div className={Style.figwrapper}>
                <figure className={Style.imgwrap}>
                  <img
                    src={data && data?.["page-details-section-one-image"]?.url}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceContentSection;
