import React from "react";
import { Link } from "react-router-dom";

import Style from "./RatesDesktopView.module.scss";

const RatesDesktopView = ({ searchInput, filteredResults, APIData }) => {
  return (
    <div className="container">
      <div className={Style.tablesec}>
        <div className={`d-none d-md-block ${Style.table}`}>
          <div className={Style.tablehead}>
            <div className={Style.index}>#</div>
            <div className={Style.currency}>Currency</div>
            <div className={Style.dd_dt}>DD/TT</div>
            <div className={Style.fc_buy}>FC Buy</div>
            <div className={Style.fc_cell}>FC Sell</div>
          </div>
          <div className={Style.bodynote}>
            <ul className={Style.tablebody}>
              {searchInput.length > 1
                ? filteredResults.map((value, key) => {
                 return (
                      <li>
                        <div className={Style.index}>{key + 1}</div>
                        <div className={Style.currency}>
                          <span className={Style.icon}>
                            <img src={value?.country_flag} alt="" />
                          </span>
                          {value?.["title-en"]}
                        </div>
                        <div className={Style.dd_dt}>{value?.dd_tt}</div>
                        <div className={Style.fc_buy}>{value?.fc_buy}</div>
                        <div className={Style.fc_cell}>{value?.fc_sell}</div>
                      </li>
                    );
                  })
                : APIData?.details?.length > 0 &&
                  APIData?.details?.map((value, key) => {
                    return (
                      <li>
                        <div className={Style.index}>{key + 1}</div>
                        <div className={Style.currency}>
                          <span className={Style.icon}>
                            <img src={value?.country_flag} alt="" />
                          </span>
                          {value?.["title-en"]}
                        </div>
                        <div className={Style.dd_dt}>{value?.dd_tt}</div>
                        <div className={Style.fc_buy}>{value?.fc_buy}</div>
                        <div className={Style.fc_cell}>{value?.fc_sell}</div>
                      </li>
                    );
                  })}
            </ul>
            <div className={Style.tablenote}>
              <p>
                Rates are indicative and subject to change. Contact our{" "}
                <Link to={"/branch-locator"}>Nearest Branch</Link> for the
                latest rates.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatesDesktopView;
