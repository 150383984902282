import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCareersJobs } from "../../../logic/useCareersJobs";
import IcoMoon from "../../Layouts/IcoMooon";
import Style from "./CareerJobMobileView.module.scss";

const CareerJobMobileView = () => {
  const { careersJobData, fetchCarrerJobs } = useCareersJobs({
    posts_per_page: 2,
    page: 1,
  });

  const [pageNumber, setPageNumber] = useState(1);

  const loadMoreclick = () => {
    if (
      careersJobData?.details?.current_page !=
      careersJobData?.details?.total_pages
    ) {
      const nextPage = careersJobData?.details?.current_page + 1;
      setPageNumber(nextPage);
    }
  };

  useEffect(() => {
    if (pageNumber != 1) {
      fetchCarrerJobs({
        posts_per_page: 2,
        page: pageNumber,
      });
    }
  }, [pageNumber]);

  return (
    <div className={Style.joblistmobview}>
      {careersJobData?.details?.jobs &&
        careersJobData?.details?.jobs.length > 0 &&
        careersJobData?.details?.jobs.map((value, key) => {
          return (
            <Link
              to={`/careers-detail/${value.slug}/${value.ID}`}
              className={Style.resultitem}
              key={key}
            >
              <div className={Style.rolename}>
                {value.role}{" "}
                <div className={Style.desig}>
                  {value.department}&nbsp;/&nbsp;{value.location}
                </div>
              </div>
              <IcoMoon icon="swiper-next" size={16} />
            </Link>
          );
        })}

      {careersJobData?.details?.current_page !=
        careersJobData?.details?.total_pages && (
        <Button className={`btn btn-brdr ${Style.btn}`} onClick={loadMoreclick}>
          <span>Load More</span>
        </Button>
      )}
    </div>
  );
};

export default CareerJobMobileView;
