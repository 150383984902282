import { useEffect, useState } from "react";
import { getCurrencyTableRates } from "../store/slices/currencyRatesSlice";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

export const useCurrencyTableRates = () => {
  const [currencyTable, setCurrencyTable] = useState([]);
  const dispatch = useDispatch();
  const currencyTableRatesData = useSelector((state) => state.currencyRates);

  useEffect(() => {
    if (currencyTableRatesData.status === "idle") {
      menuFooter();
    }
  });

  const menuFooter = async () => {
    const resultAction = await dispatch(getCurrencyTableRates());

    if (getCurrencyTableRates.fulfilled.match(resultAction)) {
      setCurrencyTable(resultAction?.payload?.data);
    }
  };

  return {
    currencyTableRatesData: currencyTable,
  };
};
