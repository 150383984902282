import React from "react";
import { Link } from "react-router-dom";
import Style from "../../Footer.module.scss";
import { useSelector } from "react-redux";
import {  useLocation } from "react-router-dom";

const CompanyMenu = () => {
  let location = useLocation();
  const { footerData } = useSelector((state) => state.footer);

  return (
    <>
      <div className="mobile-hide">
        {footerData && <div className={Style.titlesec}>Company</div>}
        <div className={Style.menuwrap}>
          <ul>
            {footerData?.details?.menus.footer_menu_company_part1 &&
              footerData?.details?.menus?.footer_menu_company_part1.length >
                0 &&
              footerData?.details?.menus?.footer_menu_company_part1.map(
                (value, key) => {
                  let activeMenu1 = location.pathname == value.url;
                  return (
                    <li key={key} className={activeMenu1 ? Style.active : ""}>
                      <Link key={value.id} to={value.url ? value.url : "/"}>
                        {value.title}
                      </Link>
                    </li>
                  );
                }
              )}
          </ul>
          <ul>
            {footerData?.details?.menus.footer_menu_company_part2 &&
              footerData?.details?.menus?.footer_menu_company_part2.length >
                0 &&
              footerData?.details?.menus?.footer_menu_company_part2.map(
                (value, key) => {
                  let activeMenu2 = location.pathname == value.url;
                  return (
                    <li key={key} className={activeMenu2 ? Style.active : ""}>
                      <Link key={value.id} to={value.url ? value.url : "/"}>
                        {value.title}
                      </Link>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default CompanyMenu;
