import React, { useEffect } from "react";
import Style from "./CareerFuture.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Assets from "../Layouts/Assets";
gsap.registerPlugin(ScrollTrigger);

const CareerFuture = () => {
  const { careersData, isActive } = useSelector((state) => state.careers);

  useEffect(() => {
    gsap.fromTo(
      ".imgwrap_01 img",
      {
        y: -40,
        scrollTrigger: {
          trigger: ".imgwrap_01",
          scrub: 1.2,
        },
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: ".imgwrap_01",
          scrub: 1.2,
        },
      },
      0.2
    );
    gsap.fromTo(
      ".imgwrap_02 img",
      {
        x: 40,
        scrollTrigger: {
          trigger: ".imgwrap_01",
          scrub: 1.2,
        },
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: ".imgwrap_01",
          scrub: 1.2,
        },
      },
      0.2
    );
    gsap.fromTo(
      ".imgwrap_03 img",
      {
        x: -40,
        scrollTrigger: {
          trigger: ".imgwrap_01",
          scrub: 1.2,
        },
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: ".imgwrap_01",
          scrub: 1.2,
        },
      },
      0.2
    );
  });
  return (
    <>
      {careersData && (
        <div
          className={
            isActive
              ? `${Style.careerfuturesec} ${Style.loaded}`
              : Style.careerfuturesec
          }
        >
          <div className="container">
            <div className={Style.outerwrap}>
              <div className={Style.wrapper}>
                <div className={Style.textcol}>
                  <div className={Style.textwrap}>
                    <header className={`smtitle ${Style.smtitle}`}>
                      {
                        careersData?.details?.page_sections?.[
                          "build-future-with-us-field-set"
                        ]?.["build-future-with-us-field-set-small-text"]
                      }
                    </header>
                    <h2>
                      {ReactHtmlParser(
                        careersData?.details?.page_sections?.[
                          "build-future-with-us-field-set"
                        ]?.["build-future-with-us-field-set-main-text"]
                      )}
                    </h2>
                    <p>
                      {ReactHtmlParser(
                        careersData?.details?.page_sections?.[
                          "build-future-with-us-field-set"
                        ]?.["build-future-with-us-field-set-main-description"]
                      )}
                    </p>
                    {/* Hide This at @media max-width: 767.98px */}
                    <Link to="/about-us" className={`arw-link ${Style.arw_link}`}>
                      <span>
                        <IcoMoon icon="arrow-right" size={12} />
                      </span>
                      Know More
                    </Link>
                  </div>
                </div>
                <div className={Style.imgcol}>
                  <div className={Style.wrapouter}>
                    <div className={Style.figwrap}>
                      <figure className={`${Style.imgwrap} imgwrap_01`}>
                        <img
                          src={
                            careersData?.details?.page_sections?.[
                              "build-future-with-us-field-set"
                            ]?.["build-future-with-us-field-set-image-1"]?.url
                          }
                          alt=""
                        />
                      </figure>
                    </div>
                    <div className={Style.figwrap}>
                      <figure className={`${Style.imgwrap} imgwrap_02`}>
                        <img
                          src={
                            careersData?.details?.page_sections?.[
                              "build-future-with-us-field-set"
                            ]?.["build-future-with-us-field-set-image-2"]?.url
                          }
                          alt=""
                        />
                      </figure>
                    </div>
                    <div className={Style.figwrap}>
                      <figure className={`${Style.imgwrap} imgwrap_03`}>
                        <img
                          src={
                            careersData?.details?.page_sections?.[
                              "build-future-with-us-field-set"
                            ]?.["build-future-with-us-field-set-image-3"]?.url
                          }
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CareerFuture;
